import React, { useState, useEffect, useContext, useRef } from 'react'
import ContextStates from '../context/contextStates'
import { useFormik } from "formik"
import axios from 'axios'
import * as Yup from "yup"
import { FaFilePdf } from "react-icons/fa6";
import { FaFileAlt } from "react-icons/fa";
import { SiMicrosoftexcel, SiMicrosoftword } from "react-icons/si";
import { Url } from './Url'
import SubirImagenes from './SubirImagenes'
import ListImputaciones from './listImputaciones'
import FormImputaciones from './formImputaciones'

const FEditCertificado = () => {
    const [lineasContrato, setLineasContrato] = useState([])
    const [lineaContrato, setLineaContrato] = useState(0)
    const [lineas, setLineas] = useState([])
    const [agregar, setAgregar] = useState(false)
    const [guardar, setGuardar] = useState(false)
    const [enviar, setEnviar] = useState(false)
    const [fechaInicio, setFechaInicio] = useState('')
    const [fechaFin, setFechaFin] = useState('')
    const [observaciones, setObservaciones] = useState('')
    
    const { setOpenVista, modify, certSelected, setModify, setMensaje,
        setTipoMensaje, idCertificado, setIdCertificado, setImageSelected,
        setFormPrev, tipoModal, setTipoModal, idUsuario, setFileselected,
        setSource, tipoUsrLogin } = useContext(ContextStates)

    let tipoImagen = "certificados";
    let idHtml = "imagenesCertificado";
    const childRef = useRef()

    useEffect(() => {
        if (idCertificado !== 0) {
            childRef.current.handleSubmit();
        }
    }, [idCertificado])

    useEffect(() => {
        const getLineasContrato = async () => {
            const formData = new FormData()
            const auxLineas = JSON.stringify(lineas)
            formData.append('id', certSelected.idcontrato);
            formData.append('lineas', auxLineas);
            formData.append('fechainicio', fechaInicio);
            formData.append('fechafin', fechaFin);
            try {
                const response = await axios({
                    url: Url + 'api/lineasListEdit.php',
                    method: 'POST',
                    data: formData,
                })
                //console.log(response)
                setLineasContrato(response.data.results)
                setLineaContrato(response.data.results[0].idlinea)
            } catch (e) {
                console.log(e)
            }
        }
        getLineasContrato()
    }, [lineas])

    useEffect(() => {
        const cargarArray = () => {
            let i = 0;
            let rData = [];
            certSelected.lineas.forEach(element => {
                rData.push({
                    "index": i,
                    "idlinea": element.idlinea,
                    "idlineaco": element.idlineaco,
                    "descripcion": element.descripcion,
                    "tarifabase": element.tarifabase,
                    "coeficiente": element.coeficiente,
                    "valoractualizado": element.valoractualizado,
                    "unidad": element.unidad,
                    "idunidad": element.idunidad,
                    "cantidad": element.cantidad,
                    "total": element.total,
                    "eliminado": 0
                }
                )
                i++;
            });
            setLineas(rData)
        }
        cargarArray()

        setFechaInicio(certSelected.fechai)
        setFechaFin(certSelected.fechaf)
    }, [])

    const handleSetCantidad = (e, index) => {

        let rData = [...lineas]
        rData.forEach(element => {
            if (element.idlinea === index) {
                element.cantidad = e;
                element.total = element.cantidad * element.valoractualizado;
            }
        });
        setLineas(rData);
        setGuardar(true)
    }

    useEffect(() => {
        if (lineas.length > 0)
            setEnviar(true)
        else
            setEnviar(false)
    }, [lineas])


    const formik = useFormik({
        initialValues: {
            cantidad: ''
        },
        validationSchema: Yup.object({
            cantidad: Yup.number()
                .required("Debe ingresar una cantidad")
        }),
        onSubmit: async (valores, { resetForm }) => {
            //console.log(lineaContrato)
            let rData = [...lineas];
            let index = rData.length + 1;
            lineasContrato.forEach(element => {
                if (element.idlinea === lineaContrato) {
                    let auxValActualizado = element.tarifabase * element.coeficiente;
                    let auxTotal = auxValActualizado * valores.cantidad;
                    rData.push({
                        "index": index,
                        "idlinea": 0,
                        "idlineaco": element.idlinea,
                        "idlineacontrato": element.idlinea,
                        "descripcion": element.descripcion,
                        "tarifabase": element.tarifabase,
                        "coeficiente": element.coeficiente,
                        "valoractualizado": auxValActualizado,
                        "unidad": element.unidad,
                        "idunidad": element.idunidad,
                        "cantidad": valores.cantidad,
                        "total": auxTotal,
                        "eliminado": 0
                    })
                    setLineas(rData)
                    setGuardar(true)
                }
            });
            resetForm({ values: '' });
        }
    });


    const handleImageSelected = (imagen) => {
        setImageSelected(imagen)
        setFormPrev(tipoModal)
        setTipoModal(30)
    }

    const handleDelete = (index) => {
        let rData = [...lineas]
        rData.forEach(element => {
            if (element.idlinea === index) {
                console.log("llega")
                element.eliminado = 1
                return
            }
        });
        setLineas(rData);
        setGuardar(true)
    }

    const deleteFile = (file) => {
        setFileselected({
            id: file.id,
            archivo: file.archivo,
            tipo: file.tipo
        })
        setSource(2)
        setFormPrev(tipoModal)
        setTipoModal(40)
    }

    const handleDownload = (file, fileName) => {
        fetch(file)
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement("a");
                link.href = url;
                link.download = fileName || "downloaded-file";
                document.body.appendChild(link);

                link.click();

                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
            .catch((error) => {
                console.error("Error fetching the file:", error);
            });
    };


    const handleGuardar = async () => {
        if (guardar) {
            setIdCertificado(0)
            const auxLineas = JSON.stringify(lineas)
            const formData = new FormData()
            formData.append('idcertificado', certSelected.id)
            formData.append('fechainicio', fechaInicio);
            formData.append('fechafin', fechaFin);
            formData.append('lineas', auxLineas);
            formData.append('observaciones', observaciones)
            formData.append('idusuario', idUsuario)
            try {
                const response = await axios({
                    url: Url + 'api/certificadoSet.php',
                    'Content-Type': 'application/json',
                    method: 'POST',
                    data: formData,
                    dataType: 'JSON',
                })
                setIdCertificado(certSelected.id)
                setTipoMensaje(1)
                setMensaje("El certificado fue guardado de manera correcta")
                let auxMod = modify;
                auxMod++;
                setModify(auxMod)
                setOpenVista(false)
            } catch (e) {
                console.log(e)
            }
        }
    }

    const handleEnviar = async () => {
        const auxLineas = JSON.stringify(lineas)
        const formData = new FormData()
        formData.append('idcertificado', certSelected.id)
        formData.append('idusuario', idUsuario)
        try {
            const response = await axios({
                url: Url + 'api/certificadoSend.php',
                'Content-Type': 'application/json',
                method: 'POST',
                data: formData,
                dataType: 'JSON',
            })
            console.log(response)
            setTipoMensaje(1)
            setMensaje("El certificado fue enviado de manera correcta")
            let auxMod = modify;
            auxMod++;
            setModify(auxMod)
            setOpenVista(false)
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div className='w-full min-h-full'>
            <div className="transition-all w-full sm:p-3">
                <h2 className='text-3xl uppercase mb-3 text-center'>Editar Certificado</h2>
                <div className='my-2 bg-blue-50 rounded-lg p-3'>
                    <div className='flex w-full'>

                        <p className='text-xl mt-2 text-gray-600'><span className='font-semibold'>Nº de Contrato: </span>{certSelected.numero}</p>
                        <p className='text-xl mt-2 ml-5 text-gray-600'><span className='font-semibold'>Nº de O.C.: </span>{certSelected.numerooc}</p>

                    </div>
                    <p className='text-xl mt-2 text-gray-600'><span className='font-semibold'>Descripción del Servicio: </span>{certSelected.descripcionservicio}</p>
                </div>
                <div className='my-2 bg-blue-50 rounded-lg p-3'>
                    <p className='text-xl mt-2 text-gray-600'><span className='font-semibold'>Nº de Certificado: </span>{certSelected.numerocert}</p>
                    <div className="sm:items-start mt-2">
                        <h3 className="block text-gray-600 uppercase font-bold">Periodo</h3>
                        <div className='flex w-full'>
                            <div className='w-1/2 p-1'>
                                <label htmlFor="fechainicio" className="block text-gray-600 font-bold">Fecha de inicio</label>
                                <input
                                    className="py-2 px-3 w-full rounded bg-white"
                                    id="fechainicio"
                                    placeholder="Fecha de inicio"
                                    type="date"
                                    value={fechaInicio}
                                    onChange={(e) => setFechaInicio(e.target.value)}
                                />
                                {formik.touched.fechainicio && formik.errors.fechainicio ? (
                                    <div className='w-full block mb-2 text-red-500'>
                                        <p>{formik.errors.fechainicio}</p>
                                    </div>
                                ) : null}

                            </div>
                            <div className='w-1/2 p-1'>
                                <label htmlFor="fechafin" className="block text-gray-600 font-bold">Fecha de fin</label>
                                <input
                                    className="py-2 px-3 w-full rounded bg-white"
                                    id="fechafin"
                                    placeholder="Fecha de fin"
                                    type="date"
                                    value={fechaFin}
                                    onChange={(e) => setFechaFin(e.target.value)}
                                />
                                {formik.touched.fechafin && formik.errors.fechafin ? (
                                    <div className='w-full block mb-2 text-red-500'>
                                        <p>{formik.errors.fechafin}</p>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sm:items-start mt-5">
                    <table className='w-full'>
                        <thead>
                            <tr>
                                <th className='w-1/4 border border-black bg-blue-200'>
                                    <p className='text-center'>Descripción linea en contrato</p>
                                </th>
                                <th className='w-1/12 border border-black bg-blue-200'>
                                    <p className='text-center'>U.M</p>
                                </th>
                                <th className='w-1/12 border border-black bg-blue-200'>
                                    <p className='text-center'>Cantidad</p>
                                </th>
                                <th className='w-1/12 border border-black bg-blue-200'>
                                    <p className='text-center'>Valor unitario</p>
                                </th>
                                <th className='w-1/12 border border-black bg-blue-200'>
                                    <p className='text-center'>Ajuste</p>
                                </th>
                                <th className='w-1/6 border border-black bg-blue-200'>
                                    <p className='text-center'>Valor unitario (Actualizado)</p>
                                </th>
                                <th className='w-1/12 border border-black bg-blue-200'>
                                    <p className='text-center'>Total</p>
                                </th>
                                <th className='w-1/12 border border-black bg-blue-200'>
                                    <p className='text-center'></p>
                                </th>

                            </tr>
                        </thead>
                        <tbody>
                            {
                                lineas && (lineas.map((item, index) =>
                                    item.eliminado === 0 &&
                                    <tr key={index}>
                                        <td className='w-1/4 border border-black'>
                                            <p className='text-center'>{item.descripcion}</p>
                                        </td>
                                        <td className='w-1/12 border border-black'>
                                            <p className='text-center'>{item.unidad}</p>
                                        </td>
                                        <td className='w-1/12 border border-black'>
                                            <input type='number' className='w-full px-2' value={item.cantidad} onChange={(e) => handleSetCantidad(e.target.value, item.idlinea)} />
                                        </td>
                                        <td className='w-1/12 border border-black'>
                                            <p className='text-right pr-2'>{item.tarifabase.toLocaleString("es-AR", { maximumFractionDigits: 2 })}</p>
                                        </td>
                                        <td className='w-1/12 border border-black'>
                                            <p className='text-center'>{item.coeficiente}</p>
                                        </td>
                                        <td className='w-1/6 border border-black'>
                                            <p className='text-right pr-2'>{typeof item.valoractualizado !== 'undefined' && item.valoractualizado.toLocaleString("es-AR", { maximumFractionDigits: 2 })}</p>
                                        </td>
                                        <td className='w-1/12 border border-black'>
                                            <p className='text-right pr-2'>{typeof item.total !== 'undefined' && item.total.toLocaleString("es-AR", { maximumFractionDigits: 2 })}</p>
                                        </td>
                                        <td className='w-1/12 border border-black'>
                                            <p className='text-center'>
                                                <button
                                                    type='button'
                                                    onClick={() => handleDelete(item.idlinea)}
                                                    className='bg-red-400 text-white p-1 rounded'
                                                >Eliminar
                                                </button>
                                            </p>
                                        </td>

                                    </tr>
                                )
                                )
                            }
                        </tbody>
                    </table>
                </div>
                <form
                    className="w-full mx-auto rounded-2xl"
                    onSubmit={formik.handleSubmit}
                >
                    <div className='flex'>
                        <div className="sm:items-start mt-5 w-1/2 pr-2">
                            <label htmlFor="sLineas" className="block text-gray-600 uppercase font-bold">Descripción de Linea</label>
                            <select className='w-full h-10 px-3 rounded bg-slate-200 text-gray-700' id='sLineas' value={lineaContrato} onChange={(e) => setLineaContrato(e.target.value)}>
                                {typeof lineasContrato !== "undefined" && (
                                    lineasContrato.map(item => (
                                        <option key={item.idlinea} value={item.idlinea}>{item.descripcion}</option>
                                    )))}
                            </select>
                        </div>
                        <div className="sm:items-start mt-5 w-1/6 pr-2">
                            <label htmlFor="cantidad" className="block text-gray-600 uppercase font-bold">Cantidad</label>
                            <input
                                className="py-2 px-3 w-full rounded bg-slate-200"
                                id="cantidad"
                                placeholder="Cantidad"
                                type="number"
                                value={formik.values.cantidad}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.cantidad && formik.errors.cantidad ? (
                                <div className='w-full block mb-2 text-red-500'>
                                    <p>{formik.errors.cantidad}</p>
                                </div>
                            ) : null}
                        </div>

                        <div className="w-1/6 rounded-lg px-2 pt-4 my-5">
                            <input
                                type="submit"
                                className="w-full shadow-md p-3 bg-indigo-600 text-gray-100 hover:bg-indigo-400 transition-all uppercase font-bold rounded-lg"
                                value="Agregar linea"
                            />
                        </div>
                    </div>
                </form>
                {(tipoUsrLogin === '3' || tipoUsrLogin === '4') && (
                    <div className='my-2 bg-blue-50 rounded-lg p-3'>
                        <h3 className='text-center text-xl uppercase'>Imputaciones Contables</h3>
                        <ListImputaciones view={0} />
                        <FormImputaciones />
                    </div>
                )}
                <h3 className='text-center text-xl uppercase'>Observaciones</h3>
                {

                    typeof certSelected.estados !== 'undefined' && (

                        certSelected.estados.map((item, index) => (
                            <div key={index} className='w-full mb-2 bg-orange-100 p-2 rounded'>
                                <p><span className='font-semibold'>({item.fecha}) - {item.usuario}: </span></p>
                                {item.observaciones.map((linea, idx) => (
                                    <p key={idx} className='w-full'>{linea.linea}</p>
                                ))}
                            </div>
                        ))
                    )
                }
                <div className='w-full'>
                    <label htmlFor="observaciones" className="block text-gray-600 uppercase font-bold">Nueva Observación</label>
                    <textarea
                        className="py-2 px-3 w-full rounded border border-gray-300"
                        id="observaciones"
                        placeholder="Agregue alguna observación en caso de ser necesario"
                        value={observaciones}
                        onChange={(e) => setObservaciones(e.target.value)}
                    >
                    </textarea>
                </div>
                <div className='flex'>
                    {certSelected.archivos && certSelected.archivos.map((item, index) => (
                        <div key={index} className='mx-2'>
                            {item.tipo === 1 ? (
                                <button
                                    className='bg-gray-200 rounded-t-lg mt-3 w-full mx-2 justify-items-center'
                                    onClick={() => handleImageSelected(item.archivo)}
                                >
                                    <img src={Url + item.archivo} className='object-cover justify-center rounded-xl mt-1 mx-auto h-32' />
                                </button>
                            ) : (
                                item.tipo === 2 ? (
                                    <a href={Url + item.archivo} target="_blank" rel="noreferrer" id={12}>
                                        <button className='bg-blue-100 rounded-xl mt-3 mx-2 text-blue-600 flex text-center text-5xl p-5' type='button'><FaFilePdf className='mt-1 ml-4 mr-2' /></button>
                                    </a>
                                ) : (
                                    <button
                                        className='bg-blue-100 w-full rounded-xl mt-3 mx-2 text-blue-600 flex text-center text-5xl p-5'
                                        type='button'
                                        onClick={() => handleDownload(Url + item.archivo, item.archivo)}
                                    >
                                        {
                                            item.tipo === 3 ? (
                                                <SiMicrosoftexcel className='mt-1 ml-4 mr-2' />)
                                                : item.tipo === 4 ? (
                                                    <SiMicrosoftword className='mt-1 ml-4 mr-2' />)
                                                    : (<FaFileAlt className='mt-1 ml-4 mr-2' />)
                                        }
                                    </button>
                                )
                            )}
                            <button
                                className='bg-red-500 w-full mx-2 rounded-b text-white p-1'
                                onClick={() => deleteFile(item)}
                            >
                                Eliminar
                            </button>
                        </div>

                    ))}
                    <SubirImagenes
                        id={idCertificado}
                        tipo={tipoImagen}
                        ref={childRef}
                        idHtml={idHtml}
                    />
                </div>
                <div className='w-full mt-3'>
                    <div className='w-full flex mt-5'>
                        {certSelected.idestado !== '2' && certSelected.idestado !== '3' && certSelected.idestado !== '4' && (
                            <button
                                type="button"
                                className={`w-1/3 sm:w-1/4 shadow-md p-3 ml-3 text-white transition-all uppercase font-bold rounded-lg ${guardar ? 'bg-blue-500 hover:bg-blue-400' : 'bg-blue-200'} `}
                                onClick={() => handleGuardar()}
                            >
                                Guardar Borrador
                            </button>
                        )}
                        {certSelected.idestado === '1' && (
                            <button
                                type="button"
                                className={`w-1/3 sm:w-1/4 shadow-md p-3 ml-3 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg ${enviar ? 'bg-orange-500' : 'bg-orange-200'} `}
                                onClick={() => handleEnviar()}
                            >
                                Enviar Certificado
                            </button>
                        )}

                        <button
                            type="button"
                            className="w-1/3 sm:w-1/4 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                            onClick={() => setOpenVista(false)}
                        >
                            Salir
                        </button>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default FEditCertificado