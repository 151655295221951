import { useState, useContext, useEffect } from 'react'
import authContext from '../context/auth/authContext';
import { useFormik } from "formik"
import * as Yup from "yup"
import { useNavigate } from 'react-router-dom';
import { Url } from '../components/Url';
import AuthAlert from '../components/AuthAlert';


const Login = () => {
    const [ verLogin, setVerLogin ] = useState(true)
    const { autenticado, mensaje, procesando, iniciarSesion } = useContext(authContext);
    
    const today = new Date();
    const year = today.getFullYear();

    const navigate = useNavigate();


    useEffect(() => {

        if (autenticado) {
            navigate("/", { replace: true });
        }
    }, [autenticado])


    const formik = useFormik({
        initialValues:{
            mail: '',
            password: ''
        },
        validationSchema: Yup.object({
            mail: Yup.string()
                    .required("El usuario o email es obligatorio"),
                    
            password: Yup.string()
                    .required("Debe ingresar un Password")
                    .min(6,"Debe contar con un mínimo de 6 caracteres")
        }),
        onSubmit: valores => {
            //setProcesando(true)
            iniciarSesion(valores)
            //setProcesando(false)
        }
    });

    return (
        <div className="flex flex-col h-screen justify-between">
            <header className="">
                <div className='w-full bg-slate-50 h-40 p-2 mb-5 shadow-lg'>
                    

                </div>
                <h2 className='text-gray-500 text-3xl text-center'>Sistema de certificación de contratos</h2>
                <h2 className='mt-5 text-gray-500 text-2xl text-center'>Iniciar Sesión</h2>
            </header>
            <main className="mb-auto mx-auto w-11/12 md:w-3/4 lg:w-1/2">
                { mensaje && <AuthAlert/>}
                <form
                    className="w-full md:w-3/4 mx-auto mt-5 p-5 bg-slate-100 rounded-2xl"
                    onSubmit={formik.handleSubmit}
                >
                    <div className="transition-all w-full pt-5 sm:p-3 mt-5">
                        <div className="sm:items-start">
                            <label htmlFor="mail" className="block mt-5 text-gray-400 uppercase font-bold">Usuario o E-mail</label>
                            <input 
                                className="py-2 px-3 w-full rounded" 
                                id="mail" 
                                placeholder="Ingrese su nombre de usuario o E-Mail" 
                                type="text" 
                                disabled={procesando}
                                value={formik.values.mail}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                autoFocus
                            />
                            { formik.touched.mail && formik.errors.mail ? (
                                <div className='w-full block mb-2 text-red-500'>
                                    <p>{formik.errors.mail}</p>
                                </div>
                            ) : null }
                        </div>
                        <div className="sm:items-start mt-5">
                            <label htmlFor="password" className="block text-gray-400 uppercase font-bold">Contraseña</label>
                            <input 
                                className="py-2 px-3 w-full rounded" 
                                id="password" 
                                placeholder="Ingrese su Contraseña" 
                                type="password"
                                disabled={procesando}
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            { formik.touched.password && formik.errors.password ? (
                                <div className='w-full block mb-2 text-red-500'>
                                    <p>{formik.errors.password}</p>
                                </div>
                            ) : null }
                        </div>
                        <div className="sm:items-start mt-5">
                            <button
                                type='submit'
                                disabled={procesando}
                                className='mt-10 bg-blue-600 p-3 w-full rounded-xl text-white uppercase'
                            >
                                Iniciar Sesión
                            </button>
                        </div>
                    </div>
                </form>                        
            </main>
            <footer className="h-20 p-3 bg-slate-50">
                <a href='https://mirx.com.ar' target={"_blank"}>
                    <p className='text-center text-gray-400 font-light text-xl'>© {year} - Mirx Software</p>
                </a>
            </footer>
        </div>
  )
}

export default Login