import { useState } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import AuthState from './context/auth/authState'
import Index from './pages/Home'
import Contratos from './pages/contratos'
import Certificados from './pages/certificados'
import Estructuras from './pages/estructuras'
import Reporting from './pages/Reporting'
import Perfil from './pages/Perfil'
import AdminUsuarios from './pages/AdminUsuarios'
import ContextStates from './context/contextStates'
import ModalAlta from './components/ModalAlta'
import ModalVista from './components/ModalVista'
import Alerta from './components/Alerta'



const App = () => {
	const [itemSidebar, setItemSidebar] = useState(0);
	const [roles, setRoles] = useState([]);
	const [tipoUsrLogin, setTipoUsrLogin] = useState(0);
	const [usuarios, setUsuarios] = useState([]);
	const [contratos, setContratos] = useState([]);
	const [certificados, setCertificados] = useState([]);
	const [idCertificado, setIdCertificado] = useState(0)
	const [contratoSelected, setContratoSelected] = useState({});
	const [idContrato, setIdContrato] = useState(0);
	const [idEstructura, setIdEstructura] = useState(0);
	const [buscarUsuario, setBuscarUsuario] = useState('');
	const [formAlta, setFormAlta] = useState(0);
	const [idUsuario, setIdUsuario] = useState(0);
	const [usuarioSelected, setUsuarioSelected] = useState({});
	const [tipoUsuario, setTipoUsuario] = useState(0);
	const [certSelected, setCertSelected] = useState({});
	const [estrSelected, setEstrSelected] = useState({});
	const [coefSelected, setCoefSelected] = useState({});
	const [idUsuarioNuevo, setIdUsuarioNuevo] = useState(0);
	const [openAlta, setOpenAlta] = useState(false);
	const [openVista, setOpenVista] = useState(false);
	const [formPrev, setFormPrev] = useState(0);
	const [tipoModal, setTipoModal] = useState(0);
	const [listadoRoles, setListadoRoles] = useState([]);
	const [mensaje, setMensaje] = useState("");
	const [tipoMensaje, setTipoMensaje] = useState(1);
	const [notification, setNotification] = useState("");
	const [messageOut, setMessageOut] = useState("");
	const [formImgView, setFormImgView] = useState(0);
	const [imageSelected, setImageSelected] = useState('');
	const [tokens, setTokens] = useState([]);
	const [modify, setModify] = useState(0);
	const [source, setSource] = useState(0);
	const [fileSelected, setFileselected] = useState({});


	return (

		<AuthState>
			<ContextStates.Provider
				value={{
					itemSidebar, setItemSidebar,
					roles, setRoles,
					tipoUsrLogin, setTipoUsrLogin,
					idUsuario, setIdUsuario,
					usuarios, setUsuarios,
					buscarUsuario, setBuscarUsuario,
					contratos, setContratos,
					certificados, setCertificados,
					idCertificado, setIdCertificado,
					contratoSelected, setContratoSelected,
					idContrato, setIdContrato,
					idEstructura, setIdEstructura,
					usuarioSelected, setUsuarioSelected,
					tipoUsuario, setTipoUsuario,
					certSelected, setCertSelected,
					estrSelected, setEstrSelected,
					coefSelected, setCoefSelected,
					formAlta, setFormAlta,
					openAlta, setOpenAlta,
					openVista, setOpenVista,
					formPrev, setFormPrev,
					idUsuarioNuevo, setIdUsuarioNuevo,
					tipoModal, setTipoModal,
					listadoRoles, setListadoRoles,
					mensaje, setMensaje,
					tipoMensaje, setTipoMensaje,
					notification, setNotification,
					messageOut, setMessageOut,
					formImgView, setFormImgView,
					imageSelected, setImageSelected,
					tokens, setTokens,
					modify, setModify,
					source, setSource,
					fileSelected, setFileselected
				}}
			>
				<BrowserRouter>
					<Routes>
						<Route path='/' element={<Index />} />
						<Route path='/contratos' element={<Contratos />} />
						<Route path='/certificados' element={<Certificados />} />
						<Route path='/estructuras' element={<Estructuras />} />
						<Route path='/reporting' element={<Reporting />} />
						<Route path='/adminusuarios' element={<AdminUsuarios />} />
						<Route path='/perfil' element={<Perfil />} />
					</Routes>
					<Alerta />
					<ModalAlta />
					<ModalVista />
				</BrowserRouter>
			</ContextStates.Provider>
		</AuthState>
	)
}

export default App
