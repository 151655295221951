import { Link } from 'react-router-dom';
import { useContext } from "react";
import Layout from '../components/Layout';
import ContextStates from '../context/contextStates';
import ValoresInicio from '../components/valoresInicio';

const Index = () => {
	const { tipoUsrLogin } = useContext(ContextStates)


	return (
		<Layout
			pagina="Inicio"
		>
			<div className="w-full flex-1 bg-gray-100">
				{(tipoUsrLogin !== '2' && tipoUsrLogin !== '4') && (
					<h1 className="mt-10 mb-5 text-gray-500 text-3xl text-center">Administración de certificados</h1>
				)}
				<div className="w-full bg-white p-5 md:flex-1 min-h-screen">
				<ValoresInicio/>
				</div>
			</div>
		</Layout>
	)
}

export default Index