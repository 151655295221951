import { Fragment, useRef, useContext } from 'react'
import { Dialog, Transition } from '@headlessui/react'

import FViewContrato from './FViewContrato'
import FEditContrato from './FEditContrato'
import FViewCertificado from './FViewCertificado'
import FEditCertificado from './FEditCertificado'
import FEditEstructura from './FEditEstructura'
import FDelContrato from './FDelContrato'
import FViewUsuario from './FViewUsuario'
import FEditUsuario from './FEditUsuario'
import FDeleteUsuario from './FDeleteUsuario'
import ContextStates from '../context/contextStates'
import FViewEstructura from './FViewEstructura'
import FDelEstructura from './FDelEstructura'
import FViewImagen from './FViewImagen'
import FDelFile from './FDelFile'


const ModalVista = () => {
	const { tipoModal, openVista, setOpenVista } = useContext(ContextStates);


	const cancelButtonRef = useRef(null)

	return (
		<>
			<Transition.Root show={openVista} as={Fragment}>
				<Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpenVista}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<div className="fixed z-10 inset-0 overflow-y-auto">
						<div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<Dialog.Panel className="relative bg-white rounded-xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 w-11/12">
									<div className="px-4 pt-2 sm:p-6">
										
										{ 	tipoModal === 1 ? (
											<FViewContrato />
										) : tipoModal === 2 ? (
											<FEditContrato />
										) : tipoModal === 4 ? (
											<FViewUsuario />
										) : tipoModal === 5 ? (
											<FEditUsuario />
										) : tipoModal === 6 ? (
											<FDeleteUsuario />
										) : tipoModal === 8 ? (
											<FDelContrato />
										) : tipoModal === 9 ? (
											<FViewEstructura />
										) : tipoModal === 10 ? (
											<FEditEstructura />
										) : tipoModal === 11 ? (
											<FDelContrato />
										) : tipoModal === 12 ? (
											<FDelEstructura />
										) : tipoModal === 21 ? (
											<FViewCertificado />
										) : tipoModal === 22 ? (
											<FEditCertificado />
										) : tipoModal === 30 ? (
											<FViewImagen />
										) : tipoModal === 40 ? (
											<FDelFile />
										) : null }
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>

		</>
	)
}

export default ModalVista