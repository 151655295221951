import React, { useState, useEffect, useContext, useRef } from 'react'
import ContextStates from '../../context/contextStates'
import { useFormik } from "formik"
import axios from 'axios'
import * as Yup from "yup"
import { Url } from '../Url'
import Select from 'react-select';
import SubirImagenes from '../SubirImagenes'

const FormCertificado = () => {
    const [lineasContrato, setLineasContrato] = useState([])
    const [lineaContrato, setLineaContrato] = useState(0)
    const [lineas, setLineas] = useState([])
    const [agregar, setAgregar] = useState(false)
    const [guardar, setGuardar] = useState(false)
    const [fechaInicio, setFechaInicio] = useState('')
    const [fechaFin, setFechaFin] = useState('')
    const [observaciones, setObservaciones] = useState('')
    const [errorFecha, setErrorFecha] = useState(false)
    const { setOpenAlta, modify, contratoSelected, setModify, setMensaje, setTipoMensaje, idCertificado, setIdCertificado, idUsuario } = useContext(ContextStates)

    let tipoImagen = "certificados";
    let idHtml = "imagenesCertificado";
    const childRef = useRef()

    useEffect(() => {
        if (idCertificado !== 0) {
            childRef.current.handleSubmit();
        }
    }, [idCertificado])


    useEffect(() => {
        const getLineasContrato = async () => {
            const formData = new FormData()
            const auxLineas = JSON.stringify(lineas)
            formData.append('id', contratoSelected.id);
            formData.append('lineas', auxLineas);
            try {
                const response = await axios({
                    url: Url + 'api/lineasList.php',
                    method: 'POST',
                    data: formData,
                })
                console.log(response)
                setLineasContrato(response.data.results)
                setLineaContrato(response.data.results[0].idlinea)
            } catch (e) {
                console.log(e)
            }
        }
        getLineasContrato()
    }, [lineas])


    useEffect(() => {
        if (lineas.length > 0) {
            if (errorFecha)
                setGuardar(false)
            else
                setGuardar(true)
        }
        else
            setGuardar(false)
    }, [lineas, errorFecha])


    const formik = useFormik({
        initialValues: {
            cantidad: ''
        },
        validationSchema: Yup.object({
            cantidad: Yup.number()
                .required("Debe ingresar una cantidad")
        }),
        onSubmit: async (valores, { resetForm }) => {

            let rData = [...lineas];
            let index = rData.length + 1;
            lineasContrato.forEach(element => {
                //console.log("lineas ", element.idlinea, " - id ", lineaContrato)
                if (element.idlinea === lineaContrato) {
                    rData.push({
                        "index": index,
                        "idlinea": element.idlinea,
                        "descripcion": element.descripcion,
                        "tarifabase": element.tarifabase,
                        "unidad": element.unidad,
                        "cantidad": valores.cantidad
                    })
                    setLineas(rData)
                }
            });
            resetForm({ values: '' });
        }
    });

    useEffect(() => {
        const getLastDayOfMonth = (year, month) => {
            let date = new Date(year, month, 0);
            return date.getDate();
        }

        const vFechaI = fechaInicio.split('-');
        const dia = parseInt(vFechaI[2]);
        const mes = parseInt(vFechaI[1]);
        const anio = parseInt(vFechaI[0]);
        let ultDia = 0;
        let ultMes = 0;
        let ultAnio = 0;
        if (dia === 1) {
            ultDia = getLastDayOfMonth(anio, mes);
            ultMes = mes;
            ultAnio = anio;
        }
        else {
            ultDia = dia - 1;
            if (mes === 12) {
                ultMes = 1;
                ultAnio = anio + 1;
            }
            else {
                ultMes = mes + 1;
                ultAnio = anio;
            }
        }
        let diaDef = '';
        let mesDef = '';

        if (ultDia < 10)
            diaDef = "0" + ultDia;
        else
            diaDef = ultDia;


        if (ultMes < 10)
            mesDef = "0" + ultMes;
        else
            mesDef = ultMes;

        let fechaF = '';

        fechaF = ultAnio + "-" + mesDef + "-" + diaDef;
        setFechaFin(fechaF);
    }, [fechaInicio])

    useEffect(() => {
        if (fechaInicio !== '') {
            const vFechaI = fechaInicio.split('-');
            const diaI = parseInt(vFechaI[2]);
            const mesI = parseInt(vFechaI[1]);
            const anioI = parseInt(vFechaI[0]);
            const vFechaF = fechaFin.split('-');
            const diaF = parseInt(vFechaF[2]);
            const mesF = parseInt(vFechaF[1]);
            const anioF = parseInt(vFechaF[0]);
            if (anioF > anioI) {
                if (mesI == '12' && mesF == '01') {
                    if (diaI <= diaF)
                        setErrorFecha(true)
                    else
                        setErrorFecha(false)
                }
                else {
                    setErrorFecha(true)
                }
            }
            else {
                if (anioI > anioF) {
                    setErrorFecha(true)
                }
                else {
                    if (mesI > mesF) {
                        setErrorFecha(true)
                    }
                    else {
                        if (mesF > mesI) {
                            let iMesI = parseInt(mesI);
                            let iMesF = parseInt(mesF);
                            let res = iMesF - iMesI;
                            if (res > 1) {
                                setErrorFecha(true)
                            }
                            else {
                                if (diaI <= diaF)
                                    setErrorFecha(true)
                                else
                                    setErrorFecha(false)
                            }
                        }
                        else {
                            if (diaI > diaF)
                                setErrorFecha(true)
                            else
                                setErrorFecha(false)
                        }
                    }
                }

            }
        }
    }, [fechaFin])

    const handleDelete = (index) => {
        let rData = []
        lineas.forEach(element => {
            if (element.index !== index) {
                rData.push(element)
            }
        });
        setLineas(rData);
    }

    const handleGuardar = async () => {
        if (guardar) {
            const auxLineas = JSON.stringify(lineas)
            const formData = new FormData()
            formData.append('idcontrato', contratoSelected.id)
            formData.append('fechainicio', fechaInicio);
            formData.append('fechafin', fechaFin);
            formData.append('lineas', auxLineas);
            formData.append('observaciones', observaciones)
            formData.append('idusuario', idUsuario)
            try {
                const response = await axios({
                    url: Url + 'api/certificadoAlta.php',
                    'Content-Type': 'application/json',
                    method: 'POST',
                    data: formData,
                    dataType: 'JSON',
                })
                setIdCertificado(response.data.results.idcertificado)
                setTipoMensaje(1)
                setMensaje("El certificado fue guardado de manera correcta")
                setLineas([]);
                let auxMod = modify;
                auxMod++;
                setModify(auxMod)
            } catch (e) {
                console.log(e)
            }
        }
    }

    const handleSelect = (val) => {
        setLineaContrato(val.idlinea)
    }


    return (
        <div className='w-full'>
            <div className="transition-all w-full sm:p-3">
                <h2 className='text-3xl uppercase mb-3 text-center'>Nuevo Certificado</h2>
                <div className='my-2 bg-slate-50 rounded-lg p-3'>
                    <div className='flex w-full'>

                        <p className='text-xl mt-2 text-gray-600'><span className='font-semibold'>Nº de Contrato: </span>{contratoSelected.numero}</p>
                        <p className='text-xl mt-2 ml-5 text-gray-600'><span className='font-semibold'>Nº de O.C.: </span>{contratoSelected.numeroOc}</p>

                    </div>
                    <p className='text-xl mt-2 text-gray-600'><span className='font-semibold'>Descripción del Servicio: </span>{contratoSelected.descripcionservicio}</p>
                    <div className='flex w-full'>
                        <p className='text-xl mt-2 text-gray-600'><span className='font-semibold'>Fecha Inicio: </span>{contratoSelected.fechainicio}</p>
                        <p className='text-xl mt-2 ml-5 text-gray-600'><span className='font-semibold'>Fecha Fin: </span>{contratoSelected.fechafin}</p>
                    </div>
                </div>
                <div className="sm:items-start mt-2">
                    <h3 className="block text-gray-600 uppercase font-bold">Periodo</h3>
                    <div className='flex w-full'>
                        <div className='w-1/2 p-1'>
                            <label htmlFor="fechainicio" className="block text-gray-600 font-bold">Fecha de inicio</label>
                            <input
                                className="py-2 px-3 w-full rounded bg-slate-200"
                                id="fechainicio"
                                placeholder="Fecha de inicio"
                                type="date"
                                value={fechaInicio}
                                onChange={(e) => setFechaInicio(e.target.value)}
                            />
                            {formik.touched.fechainicio && formik.errors.fechainicio ? (
                                <div className='w-full block mb-2 text-red-500'>
                                    <p>{formik.errors.fechainicio}</p>
                                </div>
                            ) : null}

                        </div>
                        <div className='w-1/2 p-1'>
                            <label htmlFor="fechafin" className="block text-gray-600 font-bold">Fecha de fin</label>
                            <input
                                className={`py-2 px-3 w-full rounded ${errorFecha ? 'bg-red-300' : 'bg-slate-200'} `}
                                id="fechafin"
                                placeholder="Fecha de fin"
                                type="date"
                                value={fechaFin}
                                onChange={(e) => setFechaFin(e.target.value)}
                            />
                            {formik.touched.fechafin && formik.errors.fechafin ? (
                                <div className='w-full block mb-2 text-red-500'>
                                    <p>{formik.errors.fechafin}</p>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
                <div className="sm:items-start mt-5">
                    <table className='w-full'>
                        <thead>
                            <tr>
                                <td className='w-1/3 border border-black bg-blue-200'>
                                    <p className='text-center'>Descripción linea en contrato</p>
                                </td>
                                <td className='w-1/12 border border-black bg-blue-200'>
                                    <p className='text-center'>U.M</p>
                                </td>
                                <td className='w-1/6 border border-black bg-blue-200'>
                                    <p className='text-center'>Valor unitario</p>
                                </td>
                                <td className='w-1/6 border border-black bg-blue-200'>
                                    <p className='text-center'>Cantidad</p>
                                </td>
                                <td className='w-1/6 border border-black bg-blue-200'>
                                    <p className='text-center'></p>
                                </td>

                            </tr>
                        </thead>
                        <tbody>
                            {
                                lineas && (lineas.map((item, index) =>

                                    <tr key={index}>
                                        <td className='w-1/3 border border-black'>
                                            <p className='text-center'>{item.descripcion}</p>
                                        </td>
                                        <td className='w-1/12 border border-black'>
                                            <p className='text-center'>{item.unidad}</p>
                                        </td>
                                        <td className='w-1/6 border border-black'>
                                            <p className='text-center'>{item.tarifabase.toLocaleString("es-AR",{maximumFractionDigits: 2})}</p>
                                        </td>
                                        <td className='w-1/6 border border-black'>
                                            <p className='text-center'>{item.cantidad}</p>
                                        </td>
                                        <td className='w-1/6 border border-black'>
                                            <p className='text-center'>
                                                <button
                                                    type='button'
                                                    onClick={() => handleDelete(item.index)}
                                                    className='bg-red-400 text-white p-1 rounded'
                                                >Eliminar
                                                </button>
                                            </p>
                                        </td>

                                    </tr>
                                )
                                )
                            }
                        </tbody>
                    </table>
                </div>
                <form
                    className="w-full mx-auto rounded-2xl"
                    onSubmit={formik.handleSubmit}
                >
                    <div className='flex'>
                        <div className="sm:items-start mt-5 w-1/2 pr-2">
                            <label htmlFor="sLineas" className="block text-gray-600 uppercase font-bold">Descripción de Linea</label>
                            <Select
                                options={lineasContrato}
                                placeholder="Seleccione la linea de contrato a certificar"
                                onChange={(value) => handleSelect(value)}
                                name='sLineas'
                            />
                        </div>
                        <div className="sm:items-start mt-5 w-1/6 pr-2">
                            <label htmlFor="cantidad" className="block text-gray-600 uppercase font-bold">Cantidad</label>
                            <input
                                className="py-2 px-3 w-full rounded bg-slate-200"
                                id="cantidad"
                                placeholder="Cantidad"
                                type="text"
                                value={formik.values.cantidad}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.cantidad && formik.errors.cantidad ? (
                                <div className='w-full block mb-2 text-red-500'>
                                    <p>{formik.errors.cantidad}</p>
                                </div>
                            ) : null}
                        </div>
                        <div className="w-1/6 rounded-lg px-2 pt-4 my-5">
                            <input
                                type="submit"
                                className="w-full shadow-md p-3 bg-indigo-600 text-gray-100 hover:bg-indigo-400 transition-all uppercase font-bold rounded-lg"
                                value="Agregar linea"
                            />
                        </div>
                    </div>

                </form>
                <div className='w-full'>
                    <label htmlFor="observaciones" className="block text-gray-600 uppercase font-bold">Observaciones</label>
                    <textarea
                        className="py-2 px-3 w-full rounded bg-slate-200"
                        id="observaciones"
                        placeholder="Agregue alguna observación en caso de ser necesario"   
                        value={observaciones}
                        onChange={(e) => setObservaciones(e.target.value)}
                    >
                    </textarea>
                </div>
                <div className='w-full'>
                    <SubirImagenes
                        id={idCertificado}
                        tipo={tipoImagen}
                        ref={childRef}
                        idHtml={idHtml}
                    />
                </div>
                <div className='w-full flex mt-5'>
                    <button
                        type="button"
                        className={`w-1/3 sm:w-1/4 shadow-md p-3 ml-3 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg ${guardar ? 'bg-blue-500' : 'bg-blue-200'} `}
                        onClick={() => handleGuardar()}
                    >
                        Guardar Borrador
                    </button>
                    <button
                        type="button"
                        className="w-1/3 sm:w-1/4 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                        onClick={() => setOpenAlta(false)}
                    >
                        Salir
                    </button>

                </div>
            </div>
        </div>
    )
}

export default FormCertificado