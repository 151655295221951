import React, { useState, useContext, useEffect } from 'react'
import Layout from '../components/Layout';
import ContextStates from '../context/contextStates';
import { AiOutlinePlus, AiOutlineSearch } from 'react-icons/ai'
import ListContratos from '../components/listContratos';
import { RiFileExcel2Line } from 'react-icons/ri'

const Contratos = () => {
	const [tab, setTab] = useState(1);
	const { setOpenAlta, setFormAlta, tipoUsrLogin } = useContext(ContextStates);

	const Alta = (opc) => {
		switch (opc) {
			case 1:
				setFormAlta(1);
				break;
			case 2:
				setFormAlta(2);
				break;
		}
		setOpenAlta(true)
	}

	const Import = () => {
		setFormAlta(20);
		setOpenAlta(true);
	}
	const ImportLineas = () => {
		setFormAlta(22);
		setOpenAlta(true);
	}

	return (
		<Layout
			pagina="Contratos"
		>
			<div className="w-full flex-1 bg-slate-50">
				<h1 className="mt-10 mb-5 text-gray-400 text-3xl text-center">Contratos</h1>

				<div className='w-full mt-2 bg-white'>
					<div className='w-full h-16 py-2 px-10 bg-white flex'>
						{tipoUsrLogin === '1' && (
							<>
								<button
									className='bg-indigo-600 hover:bg-indigo-400 shadow-lg p-3 rounded-md text-white uppercase flex'
									onClick={() => Alta(1)}
								>
									<AiOutlinePlus className='text-2xl mr-2' />
									Crear Contrato
								</button>
								<button
									className='bg-green-600 hover:bg-green-400 shadow-lg ml-5 p-3 rounded-md text-white uppercase flex'
									onClick={() => Import()}
								>
									<p className='text-xl mr-2'>
										<RiFileExcel2Line />
									</p>
									Importar Contratos
								</button>
								<button
									className='bg-green-600 hover:bg-green-400 shadow-lg ml-5 p-3 rounded-md text-white uppercase flex'
									onClick={() => ImportLineas()}
								>
									<p className='text-xl mr-2'>
										<RiFileExcel2Line />
									</p>
									Importar Lineas
								</button>
							</>
						)}
					</div>
					<div className='w-full h-screen p-10 bg-white overflow-scroll'>
						<ListContratos />
					</div>
				</div>
			</div>
		</Layout>
	)
}

export default Contratos