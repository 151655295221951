import { useContext, useEffect, useState, useRef } from 'react'
import ContextStates from '../context/contextStates';
import axios from 'axios';
import { Url } from './Url'
import { useFormik } from "formik"
import * as Yup from "yup"
import SubirImagenes from './SubirImagenes'
import { FaFilePdf } from "react-icons/fa6";
import { FaFileAlt } from "react-icons/fa";
import { SiMicrosoftexcel, SiMicrosoftword } from "react-icons/si";
import Select from 'react-select';


const FEditContrato = () => {
    const [proveedores, setProvedores] = useState([])
    const [proveedor, setProveedor] = useState(0)
    const [supervisores, setSupervisores] = useState([])
    const [supervisor, setSupervisor] = useState(0)
    const [gerentes, setGerentes] = useState([])
    const [gerente, setGerente] = useState(0)
    const [monedas, setMonedas] = useState([])
    const [moneda, setMoneda] = useState(0)
    const [unidades, setUnidades] = useState([])
    const [unidad, setUnidad] = useState(0)
    const [coeficientes, setCoeficientes] = useState([])
    const [coeficiente, setCoeficiente] = useState(0)
    const [numero, setNumero] = useState('')
    const [numeroOc, setNumeroOc] = useState('')
    const [descripcionServicio, setDescripcionServicio] = useState('')
    const [lineas, setLineas] = useState([])
    const [fechaInicio, setFechaInicio] = useState('')
    const [fechaFin, setFechaFin] = useState('')
    const [admins, setAdmins] = useState([])
    const [admin, setAdmin] = useState(0)
    const [areas, setAreas] = useState([])
    const [area, setArea] = useState(0)
    const [modificado, setModificado] = useState(false)
    const [guardar, setGuardar] = useState(false)
    const { contratoSelected, setMensaje, setTipoMensaje, modify, setModify, tipoUsrLogin, idContrato, setIdContrato, setOpenVista, setImageSelected, setFormPrev, tipoModal, setTipoModal, setSource, setFileselected } = useContext(ContextStates);

    let tipoImagen = "contratos";
    let idHtml = "imagenesContrato";
    const childRef = useRef()

    useEffect(() => {
        if (idContrato !== 0) {
            childRef.current.handleSubmit();
        }
    }, [idContrato])


    useEffect(() => {
        const getProveedores = async () => {
            const formData = new FormData()
            formData.append('id', 1);
            try {
                const response = await axios({
                    url: Url + 'api/provList.php',
                    method: 'POST',
                    data: formData,
                })
                setProvedores(response.data.results)
            } catch (e) {
                console.log(e)
            }
        }
        getProveedores()
    }, [])

    useEffect(() => {
        const getSupervisores = async () => {
            const formData = new FormData()
            formData.append('id', 1);
            try {
                const response = await axios({
                    url: Url + 'api/superList.php',
                    method: 'POST',
                    data: formData,
                })
                setSupervisores(response.data.results)
            } catch (e) {
                console.log(e)
            }
        }
        getSupervisores()
    }, [])

    useEffect(() => {
        const cargarArray = () => {
            let i = 0;
            let rData = [];
            contratoSelected.lineas.forEach(element => {

                rData.push({
                    "index": i,
                    "idlinea": element.idlinea,
                    "descripcion": element.descripcion,
                    "idunidad": element.idunidad,
                    "unidad": element.unidad,
                    "tarifabase": element.tarifabase,
                    "idestructura": element.idestructura,
                    "estructura": element.estructura,
                    "eliminado": 0,
                    "nuevoReg": 0
                }
                )
                i++;
            });
            setLineas(rData)

        }
        cargarArray()
        setProveedor(contratoSelected.idproveedor)
        setSupervisor(contratoSelected.idsupervisor)
        setNumero(contratoSelected.numero)
        setNumeroOc(contratoSelected.numeroOc)
        setDescripcionServicio(contratoSelected.descripcionservicio)
        setFechaInicio(contratoSelected.fechainicio)
        setFechaFin(contratoSelected.fechafin)
        setMoneda(contratoSelected.idmoneda)
        setAdmin(contratoSelected.admin)
        setArea(contratoSelected.area)
        setGerente(contratoSelected.gerente)
    }, [monedas, admins, areas])

    useEffect(() => {
        const checkMonedas = async () => {
            const formData = new FormData()
            formData.append('id', 1);
            try {
                const response = await axios({
                    url: Url + 'api/monedasList.php',
                    method: 'POST',
                    data: formData,
                })
                setMonedas(response.data.results)
            } catch (e) {
                console.log(e)
            }
        }
        checkMonedas()
    }, [])

    useEffect(() => {
        const checkUnidades = async () => {
            const formData = new FormData()
            formData.append('id', 1);
            try {
                const response = await axios({
                    url: Url + 'api/unidadesList.php',
                    method: 'POST',
                    data: formData,
                })
                setUnidades(response.data.results)
                setUnidad(response.data.results[0].id)
            } catch (e) {
                console.log(e)
            }
        }
        checkUnidades()
    }, [])

    useEffect(() => {
        const getAdmins = async () => {
            const formData = new FormData()
            formData.append('id', 1);
            try {
                const response = await axios({
                    url: Url + 'api/adminList.php',
                    method: 'POST',
                    data: formData,
                })
                setAdmins(response.data.results)
            } catch (e) {
                console.log(e)
            }
        }
        getAdmins()
    }, [])

    useEffect(() => {
        const getAreas = async () => {
            const formData = new FormData()
            formData.append('id', 1);
            try {
                const response = await axios({
                    url: Url + 'api/areasList.php',
                    method: 'POST',
                    data: formData,
                })
                setAreas(response.data.results)
            } catch (e) {
                console.log(e)
            }
        }
        getAreas()
    }, [])

    useEffect(() => {
        const getGerentes = async () => {
            const formData = new FormData()
            formData.append('id', 1);
            try {
                const response = await axios({
                    url: Url + 'api/gerentesList.php',
                    method: 'POST',
                    data: formData,
                })
                setGerentes(response.data.results)
            } catch (e) {
                console.log(e)
            }
        }
        getGerentes()
    }, [])

    useEffect(() => {
        setModificado(true)
    }, [gerente, admin, area, supervisor])

    useEffect(() => {
        if (lineas.length > 0 && proveedor !== '0' && modificado)
            setGuardar(true)
        else
            setGuardar(false)
    }, [lineas, proveedor, gerente, admin, area, supervisor])

    useEffect(() => {
        const getCoeficiente = async () => {
            const formData = new FormData()
            try {
                const response = await axios({
                    url: Url + 'api/estructurasList.php',
                    method: 'POST',
                    data: formData,
                })
                setCoeficientes(response.data.results)
            } catch (e) {
                console.log(e)
            }
        }
        getCoeficiente()
    }, [])


    const handleDelete = (index) => {

        let rData = [...lineas]
        rData.forEach(element => {
            if (element.idlinea === index) {
                element.eliminado = 1
                setModificado(true)
                return
            }
        });
        setLineas(rData);
    }

    const handleSetCoeficiente = (e, index) => {
        let rData = [...lineas]
        //console.log("llega", e)
        rData.forEach(element => {
            if (element.idlinea === index) {
                element.idestructura = e
                setModificado(true)
                return
            }
        });
        setLineas(rData);
    }

    const handleSetUnidad = (e, index) => {

        let rData = [...lineas]
        rData.forEach(element => {
            if (element.idlinea === index) {
                element.idunidad = e
                setModificado(true)
                return
            }
        });
        setLineas(rData);
    }

    const handleSetDescripcion = (e, index) => {

        let rData = [...lineas]
        rData.forEach(element => {
            if (element.idlinea === index) {
                element.descripcion = e
                setModificado(true)
                return
            }
        });
        setLineas(rData);
    }

    const handleSetTarifa = (e, index) => {

        let rData = [...lineas]
        rData.forEach(element => {
            if (element.idlinea === index) {

                element.tarifabase = e
                setModificado(true)
                return
            }
        });
        setLineas(rData);
    }

    const handleImageSelected = (imagen) => {
        setImageSelected(imagen)
        setFormPrev(tipoModal)
        setTipoModal(30)
    }

    const formik = useFormik({
        initialValues: {
            descripcion: '',
            importe: '',
        },
        validationSchema: Yup.object({
            descripcion: Yup.string()
                .required("Debe ingresar una descripción del gasto"),
            importe: Yup.string()
                .required("Debe ingresar un importe"),
        }),
        onSubmit: async (valores, { resetForm }) => {
            let nombreCoeficiente = "";
            let rAux = [...coeficientes]
            rAux.forEach(element => {
                if (element.id === coeficiente) {
                    nombreCoeficiente = element.nombre
                    return
                }
            });

            let rData = [...lineas];
            let index = rData.length + 1;
            rData.push({
                "idlinea": 0,
                "index": index,
                "descripcion": valores.descripcion,
                "idunidad": unidad,
                "tarifabase": valores.importe,
                "idestructura": coeficiente,
                "estructura": nombreCoeficiente,
                "eliminado": 0,
                "nuevoReg": 1
            })
            setLineas(rData)
            setModificado(true)
            resetForm({ values: '' });
        }
    });

    const handleSelect = (val) => {
        setCoeficiente(val.id)
    }

    const deleteFile = (file) => {
        setFileselected({
            id: file.id,
            archivo: file.archivo,
            tipo: file.tipo
        })
        setSource(1)
        setFormPrev(tipoModal)
        setTipoModal(40)
    }

    const handleDownload = (file, fileName) => {
        fetch(file)
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement("a");
                link.href = url;
                link.download = fileName || "downloaded-file";
                document.body.appendChild(link);

                link.click();

                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
            .catch((error) => {
                console.error("Error fetching the file:", error);
            });
    };

    const handleGuardar = async () => {
        if (guardar) {
            setIdContrato(0)
            const auxLineas = JSON.stringify(lineas)
            const formData = new FormData()
            formData.append('idcontrato', contratoSelected.id);
            formData.append('numero', numero);
            formData.append('numerooc', numeroOc);
            formData.append('descripcionservicio', descripcionServicio);
            formData.append('supervisor', supervisor);
            formData.append('idgerente', gerente);
            formData.append('fechainicio', fechaInicio);
            formData.append('fechafin', fechaFin);
            formData.append('moneda', moneda);
            formData.append('lineas', auxLineas);
            formData.append('admin', admin);
            formData.append('area', area);
            try {
                const response = await axios({
                    url: Url + 'api/contratoSet.php',
                    'Content-Type': 'application/json',
                    method: 'POST',
                    data: formData,
                    dataType: 'JSON',
                })
                console.log(response)
                setIdContrato(response.data.results.idcontrato)
                setTipoMensaje(1)
                setMensaje("Se guardaron las modificaciones")
                setLineas([]);
                let auxMod = modify;
                auxMod++;
                setModify(auxMod)
                setOpenVista(false)
            } catch (e) {
                console.log(e)
            }
        }
    }



    return (
        <div className='mt-10 mb-10 px-10'>
            <h3 className='text-center font-semibold text-2xl'>Contrato Nº {contratoSelected.numero}</h3>
            <div className='my-2 flex'>
                <div className='w-1/4 p-1'>
                    <label htmlFor="sAdmins" className="block text-gray-600 font-bold">Administrador de Contrato</label>
                    <select className='w-full h-10 px-3 rounded bg-slate-200 text-gray-700' id='sAdmins' value={admin} onChange={(e) => setAdmin(e.target.value)}>
                        {typeof admins !== "undefined" && (
                            admins.map(item => (
                                <option key={item.id} value={item.id}>{item.nombre}</option>
                            )))}
                    </select>
                </div>
                <div className='w-1/4 p-1'>
                    <label htmlFor="sAreas" className="block text-gray-600 font-bold">Area</label>
                    <select className='w-full h-10 px-3 rounded bg-slate-200 text-gray-700' id='sAreas' value={area} onChange={(e) => setArea(e.target.value)}>
                        {typeof areas !== "undefined" && (
                            areas.map(item => (
                                <option key={item.id} value={item.id}>{item.nombre}</option>
                            )))}
                    </select>
                </div>
                <div className='w-1/4 p-1'>
                    <label htmlFor="sAreas" className="block text-gray-600 font-bold">Gerente</label>
                    <select className='w-full h-10 px-3 rounded bg-slate-200 text-gray-700' id='sGerentes' value={gerente} onChange={(e) => setGerente(e.target.value)}>
                        {typeof gerentes !== "undefined" && (
                            gerentes.map(item => (
                                <option key={item.id} value={item.id}>{item.nombre}</option>
                            )))}
                    </select>
                </div>
            </div>

            <div className='my-2 flex'>
                <div className='w-1/2 p-1'>
                    <label htmlFor="sProveedores" className="block text-gray-600 font-bold">Proveedor</label>
                    <select className='w-full h-10 px-3 rounded bg-slate-200 text-gray-700' id='sProveedores' value={proveedor} onChange={(e) => setProveedor(e.target.value)}>
                        {typeof proveedores !== "undefined" && (
                            proveedores.map(item => (
                                <option key={item.id} value={item.id}>{item.nombre}</option>
                            )))}
                    </select>
                </div>
                <div className='w-1/2 p-1'>
                    <label htmlFor="sSupervisores" className="block text-gray-600 font-bold">Supervisor a cargo</label>
                    <select className='w-full h-10 px-3 rounded bg-slate-200 text-gray-700' id='sSupervisores' value={supervisor} onChange={(e) => setSupervisor(e.target.value)}>
                        {typeof supervisores !== "undefined" && (
                            supervisores.map(item => (
                                <option key={item.id} value={item.id}>{item.nombre}</option>
                            )))}
                    </select>
                </div>
                <div className='w-1/4 p-1'>
                    <label htmlFor="numero" className="block text-gray-600 font-bold">Nº de Contrato</label>
                    <input
                        className="h-10 px-3 w-full rounded bg-slate-200"
                        id="numero"
                        placeholder="Ingrese Nº de Contrato"
                        type="text"
                        value={numero}
                        onChange={(e) => setNumero(e.target.value)}
                    />
                </div>
                <div className='w-1/4 p-1'>
                    <label htmlFor="numeroOc" className="block text-gray-600 font-bold">Nº de O.C.</label>
                    <input
                        className="h-10 px-3 w-full rounded bg-slate-200"
                        id="numeroOc"
                        placeholder="Ingrese Nº de O.C."
                        type="text"
                        value={numeroOc}
                        onChange={(e) => setNumeroOc(e.target.value)}
                    />
                </div>
            </div>
            <div className="sm:items-start mt-5 w-1/3 pr-2">
                <label htmlFor="descripcionServicio" className="block text-gray-600 uppercase font-bold">Descripción del Servicio</label>
                <input
                    className="py-2 px-3 w-full rounded bg-slate-200"
                    id="descripcionServicio"
                    placeholder="Descripción del Servicio"
                    type="text"
                    value={descripcionServicio}
                    onChange={(e) => setDescripcionServicio(e.target.value)}
                />
            </div>

            <div className="sm:items-start mt-2">
                <h3 htmlFor="descripcion" className="block text-gray-600 uppercase font-bold">Vigencia</h3>
                <div className='flex w-full'>
                    <div className='w-1/2 p-1'>
                        <label htmlFor="fechainicio" className="block text-gray-600 font-bold">Fecha de inicio</label>
                        <input
                            className="py-2 px-3 w-full rounded bg-slate-200"
                            id="fechainicio"
                            placeholder="Fecha de inicio"
                            type="date"
                            value={fechaInicio}
                            onChange={(e) => setFechaInicio(e.target.value)}
                        />
                    </div>
                    <div className='w-1/2 p-1'>
                        <label htmlFor="fechafin" className="block text-gray-600 font-bold">Fecha de fin</label>
                        <input
                            className="py-2 px-3 w-full rounded bg-slate-200"
                            id="fechafin"
                            placeholder="Fecha de fin"
                            type="date"
                            value={fechaFin}
                            onChange={(e) => setFechaFin(e.target.value)}
                        />
                    </div>
                </div>
                <div className='my-2 w-1/6'>
                    <label htmlFor="sMoneda" className="block text-gray-600 font-bold">Moneda</label>
                    <select className='w-full p-2 rounded bg-slate-200 text-gray-700' id='sMoneda' value={moneda} onChange={(e) => setMoneda(e.target.value)}>
                        {typeof monedas !== "undefined" && (
                            monedas.map(item => (
                                <option key={item.id} value={item.id}>{item.descripcion + ' - ' + item.abreviatura}</option>
                            )))}
                    </select>
                </div>

            </div>
            <div className="sm:items-start mt-5">
                <table className='w-full'>
                    <thead>
                        <tr>
                            <td className='w-1/3 border border-black bg-blue-200 text-center'>
                                Descripción linea en contrato
                            </td>
                            <td className='w-1/12 border border-black bg-blue-200 text-center'>
                                U.M
                            </td>
                            <td className='w-1/6 border border-black bg-blue-200 text-center'>
                                Valor unitario
                            </td>
                            <td className='w-1/6 border border-black bg-blue-200 text-center'>
                                Estructura
                            </td>
                            <td className='w-1/6 border border-black bg-blue-200 text-center'>

                            </td>

                        </tr>
                    </thead>
                    <tbody>

                        {
                            lineas && (lineas.map((item) =>
                                item.eliminado === 0 &&
                                <tr key={item.idlinea}>
                                    <td className='w-1/3 border border-black'>
                                        <input type='text' className='w-full px-2' value={item.descripcion} onChange={(e) => handleSetDescripcion(e.target.value, item.idlinea)} />
                                    </td>
                                    <td className='w-1/12 border border-black'>
                                        <select className='w-full h-10 px-3 rounded bg-slate-200 text-gray-700' id='sUnidad' value={item.idunidad} onChange={(e) => handleSetUnidad(e.target.value, item.idlinea)}>
                                            {unidades && (
                                                unidades.map(unid => (
                                                    <option key={unid.id} value={unid.id}>{unid.descripcion}</option>
                                                )))}
                                        </select>
                                    </td>
                                    <td className='w-1/6 border border-black'>
                                        <input type='text' className='w-full px-2 text-right' value={item.tarifabase.toLocaleString("es-AR", { maximumFractionDigits: 2 })} onChange={(e) => handleSetTarifa(e.target.value, item.idlinea)} />
                                    </td>
                                    <td className='w-1/6 border border-black'>
                                        <select className='w-full h-10 px-3 rounded bg-slate-200 text-gray-700' id='sCoeficientes' value={item.idestructura} onChange={(e) => handleSetCoeficiente(e.target.value, item.idlinea)}>
                                            {coeficientes && (
                                                coeficientes.map(coef => (
                                                    <option key={coef.id} value={coef.id}>{`${coef.nombre}`}</option>
                                                )))}
                                        </select>
                                    </td>
                                    <td className='w-1/6 border border-black'>
                                        <p className='text-center'>
                                            <button
                                                type='button'
                                                onClick={() => handleDelete(item.idlinea)}
                                                className='bg-red-400 text-white p-1 rounded'
                                            >Eliminar
                                            </button>
                                        </p>
                                    </td>

                                </tr>
                            )
                            )
                        }
                    </tbody>
                </table>
                <form
                    className="w-full mx-auto rounded-2xl"
                    onSubmit={formik.handleSubmit}
                >
                    <div className='flex'>
                        <div className="sm:items-start mt-5 w-1/3 pr-2">
                            <label htmlFor="descripcion" className="block text-gray-600 uppercase font-bold">Descripción</label>
                            <input
                                className="py-2 px-3 w-full rounded bg-slate-200"
                                id="descripcion"
                                placeholder="Descripción"
                                type="text"
                                value={formik.values.descripcion}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.descripcion && formik.errors.descripcion ? (
                                <div className='w-full block mb-2 text-red-500'>
                                    <p>{formik.errors.descripcion}</p>
                                </div>
                            ) : null}
                        </div>
                        <div className="sm:items-start mt-5 w-1/12 pr-2">
                            <label htmlFor="sUnidad" className="block text-gray-600 uppercase font-bold">U.M.</label>
                            <select className='w-full h-10 px-2 rounded bg-slate-200 text-gray-700' id='sUnidad' onChange={(e) => setUnidad(e.target.value)}>
                                {typeof unidades !== "undefined" && (
                                    unidades.map(item => (
                                        <option key={item.id} value={item.id}>{item.descripcion}</option>
                                    )))}
                            </select>
                        </div>
                        <div className="sm:items-start mt-5 w-1/6 pr-2">
                            <label htmlFor="importe" className="block text-gray-600 uppercase font-bold">Valor unitario</label>
                            <input
                                className="py-2 px-3 w-full rounded bg-slate-200"
                                id="importe"
                                placeholder="Tarifa base"
                                type="text"
                                value={formik.values.importe}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.importe && formik.errors.importe ? (
                                <div className='w-full block mb-2 text-red-500'>
                                    <p>{formik.errors.importe}</p>
                                </div>
                            ) : null}
                        </div>
                        <div className="sm:items-start mt-5 w-1/6 pr-2">
                            <label htmlFor="fecha" className="block text-gray-600 uppercase font-bold">Coeficiente</label>
                            <Select
                                options={coeficientes}
                                placeholder="Seleccione Estructura"
                                onChange={(value) => handleSelect(value)}
                                name='sEstructuras'
                            />
                        </div>
                        <div className="w-1/6 rounded-lg px-2 pt-4 my-5">
                            <input
                                type="submit"
                                className="w-full shadow-md p-3 bg-indigo-600 text-gray-100 hover:bg-indigo-400 transition-all uppercase font-bold rounded-lg"
                                value="Agregar linea"
                            />
                        </div>
                    </div>

                </form>

            </div>
            <div className='w-full flex'>
                {contratoSelected.archivos && contratoSelected.archivos.map((item, index) => (
                    <div key={index} className='mx-2'>
                        {item.tipo === 1 ? (
                            <button
                                className='bg-gray-200 rounded-t-lg mt-3 w-full mx-2 justify-items-center'
                                onClick={() => handleImageSelected(item.archivo)}
                            >
                                <img src={Url + item.archivo} className='object-cover justify-center rounded-xl mt-1 mx-auto h-32' />
                            </button>
                        ) : (
                            item.tipo === 2 ? (
                                <a href={Url + item.archivo} target="_blank" rel="noreferrer" id={12}>
                                    <button className='bg-blue-100 rounded-xl mt-3 mx-2 text-blue-600 flex text-center text-5xl p-5' type='button'><FaFilePdf className='mt-1 ml-4 mr-2' /></button>
                                </a>
                            ) :
                                <button
                                    className='bg-blue-100 w-full rounded-xl mt-3 mx-2 text-blue-600 flex text-center text-5xl p-5'
                                    type='button'
                                    onClick={() => handleDownload(Url + item.archivo, item.archivo)}
                                >
                                    {
                                        item.tipo === 3 ? (
                                            <SiMicrosoftexcel className='mt-1 ml-4 mr-2' />)
                                            : item.tipo === 4 ? (
                                                <SiMicrosoftword className='mt-1 ml-4 mr-2' />)
                                                : (<FaFileAlt className='mt-1 ml-4 mr-2' />)
                                    }
                                </button>
                        )}
                        <button
                            className='bg-red-500 w-full mx-2 rounded-b text-white p-1'
                            onClick={() => deleteFile(item)}
                        >
                            Eliminar
                        </button>
                    </div>

                ))}
                <SubirImagenes
                    id={idContrato}
                    tipo={tipoImagen}
                    ref={childRef}
                    idHtml={idHtml}
                />
            </div>
            <div className='w-full flex mt-5'>
                <button
                    type="button"
                    className={`w-1/3 sm:w-1/4 shadow-md p-3 ml-3 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg ${guardar ? 'bg-blue-500' : 'bg-blue-200'} `}
                    onClick={() => handleGuardar()}
                >
                    Guardar Contrato
                </button>
                <button
                    type="button"
                    className="w-1/3 sm:w-1/4 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                    onClick={() => setOpenVista(false)}
                >
                    Salir
                </button>

            </div>
        </div>
    )
}

export default FEditContrato