import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const BarChartGraph = props => {
    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                width={500}
                height={300}
                data={props.data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                {props.graph === 1 ? (
                    <>
                        <Bar dataKey="cantidad" fill="#ff7300" />
                    </>
                ) : props.graph === 2 ? (
                    <>
                        <Bar dataKey="cantidad" fill="#413ea0" />
                    </>
                ) : props.graph === 3 && (
                    <>
                        <Bar dataKey="promedio_confeccion" fill="#FF8042" />
                        <Bar dataKey="promedio_revision" fill="#FFBB28" />
                        <Bar dataKey="promedio_aprobacion" fill="#00C49F" />

                    </>
                )}
            </BarChart>
        </ResponsiveContainer>
    )
}

export default BarChartGraph