import { useState, useEffect, useContext } from 'react'
import ContextStates from '../context/contextStates'
import axios from 'axios'
import { FaPen, FaTrash } from 'react-icons/fa'
import { Url } from './Url'


const ListImputaciones = (props) => {
    const [filaEdit, setFilaEdit] = useState(0)
    const [enEdit, setEnEdit] = useState(false)
    const [enDelete, setEnDelete] = useState(false)
    const [cuenta, setCuenta] = useState("")
    const [cc, setCc] = useState("")
    const [afe, setAfe] = useState("")
    const [importe, setImporte] = useState("")
    const [activo, setActivo] = useState(false)

    const [imputaciones, setImputaciones] = useState([])

    const { certSelected, modify, setModify } = useContext(ContextStates)

    useEffect(() => {
        const getImputaciones = async () => {
            const formData = new FormData()
            formData.append('id', certSelected.id);
            try {
                const response = await axios({
                    url: Url + 'api/imputacionesList.php',
                    method: 'POST',
                    data: formData,
                })
                setImputaciones(response.data.results)
            } catch (e) {
                console.log(e)
            }
        }
        getImputaciones()
    }, [modify])

    const delImputacion = async (id) => {
        const formData = new FormData()
        formData.append('id', id);
        try {
            const response = await axios({
                url: Url + 'api/delImputacion.php',
                method: 'POST',
                data: formData,
            })
            let aux = modify + 1;
            setModify(aux)
            setEnDelete(false)
        } catch (e) {
            console.log(e)
        }
    }

    const setImputacion = async (id) => {
        const formData = new FormData()
        formData.append('id', id);
        formData.append('cuenta', cuenta);
        formData.append('cc', cc);
        formData.append('afe', afe);
        formData.append('importe', importe);
        try {
            const response = await axios({
                url: Url + 'api/setImputacion.php',
                method: 'POST',
                data: formData,
            })
            let aux = modify + 1;
            setModify(aux)
            setEnEdit(false)
        } catch (e) {
            console.log(e)
        }
    }



    const edit = (item, fila) => {
        setFilaEdit(fila)
        setEnEdit(true)
        setCuenta(item.cuenta)
        setCc(item.cc)
        setAfe(item.afe)
        setImporte(item.importe)
    }

    const del = (item, fila) => {
        setFilaEdit(fila)
        setEnDelete(true)
    }

    return (
        <div className='w-full mt-10'>
            {typeof imputaciones !== 'undefined' && (
                <div className='w-full'>
                    <h3 className='text-center text-xl uppercase'>Imputaciones Contables</h3>
                    <table id="tableImputaciones" className='table-auto w-full border-collapse border-spacing-5 border-slate-400 text-gray-700 rounded-xl mx-auto' >
                        <thead>
                            <tr className='uppercase bg-blue-200 text-black'>
                                <th className='border border-gray-400 p-1 text-center'>Cuenta</th>
                                <th className='border border-gray-400 p-1 text-center'>CC</th>
                                <th className='border border-gray-400 p-1 text-center'>AFE</th>
                                <th className='border border-gray-400 p-1 text-center'>Importe</th>
                                {props.view === 0 && (
                                    <th className='border border-gray-400 p-1'></th>
                                )}
                            </tr>
                        </thead>
                        <tbody >
                            {
                                imputaciones.map((item, index) => (
                                    <tr
                                        key={index}
                                        className={`hover:cursor-pointer border-b border-gray-200  ${index % 2 === 0 ? 'bg-white hover:bg-slate-200' : 'bg-slate-100 hover:bg-slate-200'}`}
                                    >
                                        <td className='border border-gray-400 p-1 text-center'>
                                            {(enEdit && filaEdit === index) ?
                                                (
                                                    <input
                                                        className={`py-2 px-3 w-full rounded bg-white border border-gray-300`}
                                                        id="cuenta"
                                                        placeholder="Cuenta"
                                                        type="text"
                                                        value={cuenta}
                                                        onChange={(e) => setCuenta(e.target.value)}
                                                    />
                                                ) :
                                                item.cuenta
                                            }
                                        </td>
                                        <td className='border border-gray-400 p-1 text-center'>
                                            {(enEdit && filaEdit === index) ?
                                                (
                                                    <input
                                                        className={`py-2 px-3 w-full rounded bg-white border border-gray-300`}
                                                        id="CC"
                                                        placeholder="cc"
                                                        type="text"
                                                        value={cc}
                                                        onChange={(e) => setCc(e.target.value)}
                                                    />
                                                ) :
                                                item.cc
                                            }
                                        </td>
                                        <td className='border border-gray-400 p-1 text-center'>
                                            {(enEdit && filaEdit === index) ?
                                                (
                                                    <input
                                                        className={`py-2 px-3 w-full rounded bg-white border border-gray-300`}
                                                        id="afe"
                                                        placeholder="AFE"
                                                        type="text"
                                                        value={afe}
                                                        onChange={(e) => setAfe(e.target.value)}
                                                    />
                                                ) :
                                                item.afe
                                            }
                                        </td>
                                        <td className='border border-gray-400 p-1 text-center'>
                                            {(enEdit && filaEdit === index) ?
                                                (
                                                    <input
                                                        className={`py-2 px-3 w-full rounded bg-white border border-gray-300`}
                                                        id="importe"
                                                        placeholder="Importe"
                                                        type="text"
                                                        value={importe}
                                                        onChange={(e) => setImporte(e.target.value)}
                                                    />
                                                ) :
                                                item.importe.toLocaleString("es-AR", { maximumFractionDigits: 2 })
                                            }
                                        </td>
                                        {props.view === 0 && (
                                            enEdit && filaEdit===index ? (
                                                <td className='border border-gray-400 p-1 text-center'>
                                                    <button
                                                        className='bg-red-500 p-2 rounded-md text-white'
                                                        onClick={() => setImputacion(item.id)}
                                                    >
                                                        Modificar
                                                    </button>
                                                    <button
                                                        className='bg-gray-400 p-2 rounded-md text-white ml-2'
                                                        onClick={() => setEnEdit(false)}
                                                    >
                                                        Cancelar
                                                    </button>
                                                </td>

                                            ) : enDelete && filaEdit===index ? (
                                                <td className='border border-gray-400 p-1 text-center'>
                                                    <button
                                                        className='bg-red-500 p-2 rounded-md text-white'
                                                        onClick={() => delImputacion(item.id)}
                                                    >
                                                        Eliminar
                                                    </button>
                                                    <button
                                                        className='bg-gray-500 p-2 rounded-md text-white ml-2'
                                                        onClick={() => setEnDelete(false)}
                                                    >
                                                        Cancelar
                                                    </button>
                                                </td>

                                            ) : (
                                                <td className='border border-gray-400 p-1 text-center'>
                                                    <button
                                                        className='bg-orange-500 p-2 rounded-md text-white'
                                                        onClick={() => edit(item, index)}
                                                    >
                                                        <FaPen />
                                                    </button>
                                                    <button
                                                        className='bg-red-500 p-2 rounded-md text-white ml-2'
                                                        onClick={() => del(item, index)}
                                                    >
                                                        <FaTrash />
                                                    </button>
                                                </td>
                                            )
                                        )}
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    )
}

export default ListImputaciones