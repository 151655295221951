import React, { useContext } from 'react'
import ContextStates from '../context/contextStates';
import axios from 'axios';
import { Url } from './Url'
import { FaFilePdf } from "react-icons/fa6";
import { FaFileAlt } from "react-icons/fa";
import { SiMicrosoftexcel, SiMicrosoftword } from "react-icons/si";


const FDelFile = () => {
    const { fileSelected, modify, setModify, setTipoMensaje, setMensaje, setTipoModal, formPrev, contratoSelected, setContratoSelected, certSelected, setCertSelected, estrSelected, setEstrSelected, source } = useContext(ContextStates);

    const quitFile = () => {
        let rData = []
        switch (source) {
            case 1:
                contratoSelected.archivos.forEach(element => {
                    if (element.id !== fileSelected.id)
                        rData.push(element)
                });
                let contratoAux = ({
                    id: contratoSelected.id,
                    descripcionservicio: contratoSelected.descripcionservicio,
                    idproveedor: contratoSelected.idproveedor,
                    proveedor: contratoSelected.proveedor,
                    idsupervisor: contratoSelected.idsupervisor,
                    administrador: contratoSelected.administrador,
                    area: contratoSelected.area,
                    nadministrador: contratoSelected.nadministrador,
                    narea: contratoSelected.narea,
                    numero: contratoSelected.numero,
                    numerooc: contratoSelected.numerooc,
                    fechacreacion: contratoSelected.fechacreacion,
                    fechainicio: contratoSelected.fechainicio,
                    fechafin: contratoSelected.fechafin,
                    idestado: contratoSelected.idestado,
                    estado: contratoSelected.estado,
                    idmoneda: contratoSelected.idmoneda,
                    moneda: contratoSelected.moneda,
                    lineas: contratoSelected.lineas,
                    archivos: rData
                })
                setContratoSelected(contratoAux)
                break;
            case 2:
                certSelected.archivos.forEach(element => {
                    if (element.id !== fileSelected.id)
                        rData.push(element)
                });
                let certAux = ({
                    id: certSelected.id,
                    idcontrato: certSelected.idcontrato,
                    descripcionservicio: certSelected.descripcionservicio,
                    numero: certSelected.numero,
                    numerooc: certSelected.numerooc,
                    numerocert: certSelected.numerocert,
                    fechainicio: certSelected.fechai,
                    fechafin: certSelected.fechaf,
                    idestado: certSelected.idestado,
                    estado: certSelected.estado,
                    fechaestado: certSelected.fechaestado,
                    observaciones: certSelected.observaciones,
                    supervisor: certSelected.supervisor,
                    usuarioestado: certSelected.usuarioestado,
                    lineas: certSelected.lineas,
                    totalgral: certSelected.totalgral,
                    archivos: rData
                })
                setCertSelected(certAux)
                break;
            case 3:
                estrSelected.archivos.forEach(element => {
                    if (element.id !== fileSelected.id) {
                        rData.push(element)
                    }
                });
                let estrAux = ({
                    id: estrSelected.id,
                    nombre: estrSelected.nombre,
                    value: estrSelected.value,
                    label: estrSelected.label,
                    coeficientes: estrSelected.coeficientes,
                    archivos: rData
                })
                setEstrSelected(estrAux)
                break;

            default:
                break;
        }
        console.log("modificado")
    }

    const handleDelete = async () => {
        const formData = new FormData()
        formData.append('id', fileSelected.id);
        formData.append('file', fileSelected.archivo);
        try {
            const response = await axios({
                url: Url + 'api/fileDel.php',
                'Content-Type': 'application/json',
                method: 'POST',
                data: formData,
                dataType: 'JSON',
            })
            quitFile()
            setTipoMensaje(1)
            setMensaje("Se eliminó el archivo seleccionado")
            let auxMod = modify;
            auxMod++;
            setModify(auxMod)
            setTipoModal(formPrev)
        } catch (e) {
            console.log(e)
        }
    }

    const handleDownload = (file, fileName) => {
        fetch(file)
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement("a");
                link.href = url;
                link.download = fileName || "downloaded-file";
                document.body.appendChild(link);

                link.click();

                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
            .catch((error) => {
                console.error("Error fetching the file:", error);
            });
    };


    return (
        <div className='mt-10 mb-10 px-10'>
            <div className='flex'>
                {fileSelected.tipo === 1 ? (
                    <img src={Url + fileSelected.archivo} className='object-cover justify-center rounded-xl mt-1 mx-auto h-32' />
                ) : (
                    fileSelected.tipo === 2 ? (
                        <a href={Url + fileSelected.archivo} target="_blank" rel="noreferrer" id={12}>
                            <button className='bg-blue-100 rounded-xl mt-3 mx-2 text-blue-600 flex text-center text-5xl p-5' type='button'><FaFilePdf className='mt-1 ml-4 mr-2' /></button>
                        </a>
                    ) :
                        <button
                            className='bg-blue-100 w-full rounded-xl mt-3 mx-2 text-blue-600 flex text-center text-5xl p-5'
                            type='button'
                            onClick={() => handleDownload(Url + fileSelected.archivo, fileSelected.archivo)}
                        >
                            {
                                fileSelected.tipo === 3 ? (
                                    <SiMicrosoftexcel className='mt-1 ml-4 mr-2' />)
                                    : fileSelected.tipo === 4 ? (
                                        <SiMicrosoftword className='mt-1 ml-4 mr-2' />)
                                        : (<FaFileAlt className='mt-1 ml-4 mr-2' />)
                            }
                        </button>
                )}
            </div>
            <div className="w-full rounded-lg mt-10 px-3 flex justify-center">
                <button
                    type="button"
                    className={`w-1/3 sm:w-1/4 shadow-md p-3 ml-3 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg bg-red-500`}
                    onClick={() => handleDelete()}
                >
                    Eliminar Archivo
                </button>
                <button
                    type="button"
                    className="w-1/4 sm:w-1/6 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                    onClick={() => setTipoModal(formPrev)}
                >
                    Salir
                </button>
            </div>
        </div>
    )
}

export default FDelFile