import { useContext } from 'react'
import ContextStates from '../context/contextStates';
import { FaFilePdf } from "react-icons/fa6";
import { FaFileAlt } from "react-icons/fa";
import { SiMicrosoftexcel, SiMicrosoftword } from "react-icons/si";
import { Url } from './Url'

const FViewContrato = () => {
    const { setOpenVista, contratoSelected, setImageSelected, setFormPrev, tipoModal, setTipoModal } = useContext(ContextStates);

    const handleDownload = (file, fileName) => {
        fetch(file)
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement("a");
                link.href = url;
                link.download = fileName || "downloaded-file";
                document.body.appendChild(link);

                link.click();

                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
            .catch((error) => {
                console.error("Error fetching the file:", error);
            });
    };

    const handleImageSelected = (imagen) => {
        setImageSelected(imagen)
        setFormPrev(tipoModal)
        setTipoModal(30)
    }

    return (
        <div className='mt-10 mb-10 px-10'>
            <p className='text-xl mt-2 text-gray-600'><span className='font-semibold'>Proveedor: </span>{contratoSelected.proveedor}</p>
            <p className='text-xl mt-2 text-gray-600'><span className='font-semibold'>Nº de Contrato: </span>{contratoSelected.numero}</p>
            <p className='text-xl mt-2 text-gray-600'><span className='font-semibold'>Nº de O.C.: </span>{contratoSelected.numeroOc}</p>
            <p className='text-xl mt-2 text-gray-600'><span className='font-semibold'>Descripción del Servicio: </span>{contratoSelected.descripcionservicio}</p>
            <p className='text-xl mt-2 text-gray-600'><span className='font-semibold'>Administrador a Cargo: </span>{contratoSelected.nadmin}</p>
            <p className='text-xl mt-2 text-gray-600'><span className='font-semibold'>Area: </span>{contratoSelected.narea}</p>
            <p className='text-xl mt-2 text-gray-600'><span className='font-semibold'>Fecha Inicio: </span>{contratoSelected.fechainicio}</p>
            <p className='text-xl mt-2 text-gray-600'><span className='font-semibold'>Fecha Fin: </span>{contratoSelected.fechafin}</p>
            <p className='text-xl mt-2 text-gray-600'><span className='font-semibold'>Estado: </span>{contratoSelected.estado}</p>
            <p className='text-xl mt-2 text-gray-600'><span className='font-semibold'>Moneda: </span>{contratoSelected.moneda}</p>
            <div className='w-full mx-auto'>
                <table className='w-full mt-4'>
                    <thead>
                        <tr>
                            <th className='w-1/3 border border-black bg-blue-200'>
                                <p className='text-center'>Descripción de linea</p>
                            </th>
                            <th className='w-1/6 border border-black bg-blue-200'>
                                <p className='text-center'>U.M.</p>
                            </th>
                            <th className='w-1/6 border border-black bg-blue-200'>
                                <p className='text-center'>Valor unitario</p>
                            </th>
                            <th className='w-1/6 border border-black bg-blue-200'>
                                <p className='text-center'>Estructura de Costos</p>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {contratoSelected.lineas && contratoSelected.lineas.map((item, index) => (
                            <tr key={index}>
                                <td className='w-1/3 border border-black'>
                                    <p className='text-center'>{item.descripcion}</p>
                                </td>
                                <td className='w-1/6 border border-black'>
                                    <p className='text-center'>{item.unidad}</p>
                                </td>
                                <td className='w-1/6 border border-black'>
                                    <p className='text-right pr-2'>{item.tarifabase.toLocaleString("es-AR",{maximumFractionDigits: 2})}</p>
                                </td>
                                <td className='w-1/6 border border-black'>
                                    <p className='text-center'>{item.estructura}</p>
                                </td>
                            </tr>)
                        )}
                    </tbody>
                </table>
            </div>
            <div className='flex'>
                {contratoSelected.archivos && contratoSelected.archivos.map((item, index) => (
                    item.tipo === 1 ? (
                        <button
                            key={index}
                            onClick={() => handleImageSelected(item.archivo)}
                        >
                            <img src={Url + item.archivo} className='object-cover rounded-xl mt-3 mx-2 h-32' />
                        </button>
                    ) : (
                    item.tipo === 2 ? (
                        <a href={Url + item.archivo} target="_blank" rel="noreferrer" id={12}>
                            <button className='bg-blue-100 rounded-xl mt-3 mx-2 text-blue-600 flex text-center text-5xl p-5' type='button'><FaFilePdf className='mt-1 ml-4 mr-2' /></button>
                        </a>
                    ) : 
                        <button
                            className='bg-blue-100 rounded-xl mt-3 mx-2 text-blue-600 flex text-center text-5xl p-5'
                            type='button'
                            key={index}
                            onClick={() => handleDownload(Url + item.archivo, item.archivo)}
                        >
                        {
                            item.tipo === 3 ? (
                            <SiMicrosoftexcel className='mt-1 ml-4 mr-2' />)
                            : item.tipo === 4 ? (
                            <SiMicrosoftword className='mt-1 ml-4 mr-2' />)
                            : (<FaFileAlt className='mt-1 ml-4 mr-2' />)
                        }
                        </button>
                    )
                ))}
            </div>

            <div className="w-full rounded-lg mt-10 px-3 flex justify-center">
                <button
                    type="button"
                    className="w-1/4 sm:w-1/6 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                    onClick={() => setOpenVista(false)}
                >
                    Salir
                </button>
            </div>
        </div>
    )
}

export default FViewContrato