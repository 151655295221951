import React, { useState, useEffect, useContext, useRef } from 'react'
import ContextStates from '../../context/contextStates'
import { useFormik } from "formik"
import axios from 'axios'
import * as Yup from "yup"
import { Url } from '../Url'
import SubirImagenes from '../SubirImagenes'
import Select from 'react-select';

const FormContrato = () => {
    const [proveedores, setProvedores] = useState([])
    const [proveedor, setProveedor] = useState(0)
    const [descripcionServicio, setDescripcionServicio] = useState('')
    const [supervisores, setSupervisores] = useState([])
    const [supervisor, setSupervisor] = useState(0)
    const [monedas, setMonedas] = useState([])
    const [moneda, setMoneda] = useState(0)
    const [unidades, setUnidades] = useState([])
    const [unidad, setUnidad] = useState(0)
    const [admins, setAdmins] = useState([])
    const [admin, setAdmin] = useState(0)
    const [areas, setAreas] = useState([])
    const [area, setArea] = useState(0)
    const [gerentes, setGerentes] = useState([])
    const [gerente, setGerente] = useState(0)

    const [numero, setNumero] = useState('')
    const [numeroOc, setNumeroOc] = useState('')
    const [lineas, setLineas] = useState([])
    const [agregar, setAgregar] = useState(false)
    const [coeficientes, setCoeficientes] = useState([])
    const [coeficiente, setCoeficiente] = useState(0)
    const [guardar, setGuardar] = useState(false)
    const [fechaInicio, setFechaInicio] = useState('')
    const [fechaFin, setFechaFin] = useState('')
    const { setOpenAlta, modify, setModify, setMensaje, setTipoMensaje, idContrato, setIdContrato } = useContext(ContextStates)

    let tipoImagen = "contratos";
    let idHtml = "imagenesContrato";
    const childRef = useRef()

    useEffect(() => {
        if (idContrato !== 0) {
            childRef.current.handleSubmit();
        }
    }, [idContrato])

    useEffect(() => {
        const getProveedores = async () => {
            const formData = new FormData()
            formData.append('id', 1);
            try {
                const response = await axios({
                    url: Url + 'api/provList.php',
                    method: 'POST',
                    data: formData,
                })
                setProvedores(response.data.results)
            } catch (e) {
                console.log(e)
            }
        }
        getProveedores()
    }, [])

    useEffect(() => {
        const getSupervisores = async () => {
            const formData = new FormData()
            formData.append('id', 1);
            try {
                const response = await axios({
                    url: Url + 'api/superList.php',
                    method: 'POST',
                    data: formData,
                })
                setSupervisores(response.data.results)
            } catch (e) {
                console.log(e)
            }
        }
        getSupervisores()
    }, [])

    useEffect(() => {
        const checkMonedas = async () => {
            const formData = new FormData()
            formData.append('id', 1);
            try {
                const response = await axios({
                    url: Url + 'api/monedasList.php',
                    method: 'POST',
                    data: formData,
                })
                setMonedas(response.data.results)
                setMoneda(response.data.results[0].id)
            } catch (e) {
                console.log(e)
            }
        }
        checkMonedas()
    }, [])

    useEffect(() => {
        const checkUnidades = async () => {
            const formData = new FormData()
            formData.append('id', 1);
            try {
                const response = await axios({
                    url: Url + 'api/unidadesList.php',
                    method: 'POST',
                    data: formData,
                })
                setUnidades(response.data.results)
                setUnidad(response.data.results[0].id)
            } catch (e) {
                console.log(e)
            }
        }
        checkUnidades()
    }, [])

    useEffect(() => {
        const getAdmins = async () => {
            const formData = new FormData()
            formData.append('id', 1);
            try {
                const response = await axios({
                    url: Url + 'api/adminList.php',
                    method: 'POST',
                    data: formData,
                })
                setAdmins(response.data.results)
            } catch (e) {
                console.log(e)
            }
        }
        getAdmins()
    }, [])

    useEffect(() => {
        const getAreas = async () => {
            const formData = new FormData()
            formData.append('id', 1);
            try {
                const response = await axios({
                    url: Url + 'api/areasList.php',
                    method: 'POST',
                    data: formData,
                })
                setAreas(response.data.results)
            } catch (e) {
                console.log(e)
            }
        }
        getAreas()
    }, [])

    useEffect(() => {
        const getGerentes = async () => {
            const formData = new FormData()
            formData.append('id', 1);
            try {
                const response = await axios({
                    url: Url + 'api/gerentesList.php',
                    method: 'POST',
                    data: formData,
                })
                setGerentes(response.data.results)
            } catch (e) {
                console.log(e)
            }
        }
        getGerentes()
    }, [])

    useEffect(() => {
        if (lineas.length > 0 && proveedor !== '0')
            setGuardar(true)
        else
            setGuardar(false)
    }, [lineas, proveedor])

    const formik = useFormik({
        initialValues: {
            descripcion: '',
            importe: '',
        },
        validationSchema: Yup.object({
            descripcion: Yup.string()
                .required("Debe ingresar una descripción del gasto"),
            importe: Yup.string()
                .required("Debe ingresar un importe"),
        }),
        onSubmit: async (valores, { resetForm }) => {
            let nombreCoeficiente = "";
            let rAux = [...coeficientes]
            rAux.forEach(element => {
                console.log(element + " " + coeficiente)
                if (element.id === coeficiente) {
                    nombreCoeficiente = element.nombre
                    return
                }
            });

            let nombreUnidad = "";
            let rUni = [...unidades]
            rUni.forEach(element => {
                //console.log(element + " " + coeficiente)
                if (element.id === unidad) {
                    nombreUnidad = element.descripcion
                    return
                }
            });


            let rData = [...lineas];
            let index = rData.length + 1;
            rData.push({
                "index": index,
                "descripcion": valores.descripcion,
                "unidad": unidad,
                "importe": valores.importe,
                "idcoeficiente": coeficiente,
                "nombrecoeficiente": nombreCoeficiente,
                "nombreunidad": nombreUnidad
            })
            setLineas(rData)
            resetForm({ values: '' });
        }
    });

    useEffect(() => {
        const getCoeficiente = async () => {
            const formData = new FormData()
            try {
                const response = await axios({
                    url: Url + 'api/estructurasList.php',
                    method: 'POST',
                    data: formData,
                })
                setCoeficientes(response.data.results)
            } catch (e) {
                console.log(e)
            }
        }
        getCoeficiente()
    }, [proveedor])

    const handleDelete = (index) => {
        let rData = []
        lineas.forEach(element => {
            if (element.index !== index) {
                rData.push(element)
            }
        });
        setLineas(rData);
    }

    const handleSelect = (val) => {
        //console.log("estruct",val.id)
        setCoeficiente(val.id)
    }

    const handleGuardar = async () => {
        if (guardar) {
            const auxLineas = JSON.stringify(lineas)
            const formData = new FormData()
            formData.append('idproveedor', proveedor);
            formData.append('idsupervisor', supervisor);
            formData.append('idgerente', gerente);
            formData.append('numero', numero);
            formData.append('oc', numeroOc);
            formData.append('descripcionservicio', descripcionServicio)
            formData.append('fechainicio', fechaInicio);
            formData.append('fechafin', fechaFin);
            formData.append('moneda', moneda);
            formData.append('lineas', auxLineas);
            formData.append('admin', admin);
            formData.append('area', area);

            try {
                const response = await axios({
                    url: Url + 'api/contratoAlta.php',
                    'Content-Type': 'application/json',
                    method: 'POST',
                    data: formData,
                    dataType: 'JSON',
                })
                setIdContrato(response.data.results.idcontrato)
                setTipoMensaje(1)
                setMensaje("El certificado fue guardado de manera correcta")
                setLineas([]);
                let auxMod = modify;
                auxMod++;
                setModify(auxMod)
            } catch (e) {
                console.log(e)
            }
        }
    }

    return (
        <div className='w-full'>
            <div className="transition-all w-full sm:p-3">
                <h2 className='text-3xl uppercase mb-3 text-center'>Nuevo Contrato</h2>
                <div className='my-2 flex'>
                    <div className='w-1/4 p-1'>
                        <label htmlFor="sAdmins" className="block text-gray-600 font-bold">Administrador de Contrato</label>
                        <select className='w-full h-10 px-3 rounded bg-slate-200 text-gray-700' id='sAdmins' value={admin} onChange={(e) => setAdmin(e.target.value)}>
                            {typeof admins !== "undefined" && (
                                admins.map(item => (
                                    <option key={item.id} value={item.id}>{item.nombre}</option>
                                )))}
                        </select>
                    </div>
                    <div className='w-1/4 p-1'>
                        <label htmlFor="sAreas" className="block text-gray-600 font-bold">Area</label>
                        <select className='w-full h-10 px-3 rounded bg-slate-200 text-gray-700' id='sAreas' value={area} onChange={(e) => setArea(e.target.value)}>
                            {typeof areas !== "undefined" && (
                                areas.map(item => (
                                    <option key={item.id} value={item.id}>{item.nombre}</option>
                                )))}
                        </select>
                    </div>
                    <div className='w-1/4 p-1'>
                        <label htmlFor="sAreas" className="block text-gray-600 font-bold">Gerente</label>
                        <select className='w-full h-10 px-3 rounded bg-slate-200 text-gray-700' id='sGerentes' value={area} onChange={(e) => setGerente(e.target.value)}>
                            {typeof gerentes !== "undefined" && (
                                gerentes.map(item => (
                                    <option key={item.id} value={item.id}>{item.nombre}</option>
                                )))}
                        </select>
                    </div>

                </div>
                <div className='my-2 flex'>
                    <div className='w-1/4 p-1'>
                        <label htmlFor="sProveedores" className="block text-gray-600 font-bold">Proveedor</label>
                        <select className='w-full h-10 px-3 rounded bg-slate-200 text-gray-700' id='sProveedores' value={proveedor} onChange={(e) => setProveedor(e.target.value)}>
                            {typeof proveedores !== "undefined" && (
                                proveedores.map(item => (
                                    <option key={item.id} value={item.id}>{item.nombre}</option>
                                )))}
                        </select>
                    </div>
                    <div className='w-1/4 p-1'>
                        <label htmlFor="sSupervisores" className="block text-gray-600 font-bold">Supervisor a cargo</label>
                        <select className='w-full h-10 px-3 rounded bg-slate-200 text-gray-700' id='sSupervisores' value={supervisor} onChange={(e) => setSupervisor(e.target.value)}>
                            {typeof supervisores !== "undefined" && (
                                supervisores.map(item => (
                                    <option key={item.id} value={item.id}>{item.nombre}</option>
                                )))}
                        </select>
                    </div>
                    <div className='w-1/4 p-1'>
                        <label htmlFor="numero" className="block text-gray-600 font-bold">Nº de Contrato</label>
                        <input
                            className="h-10 px-3 w-full rounded bg-slate-200"
                            id="numero"
                            placeholder="Ingrese Nº de Contrato"
                            type="text"
                            value={numero}
                            onChange={(e) => setNumero(e.target.value)}
                        />
                    </div>
                    <div className='w-1/4 p-1'>
                        <label htmlFor="numeroOc" className="block text-gray-600 font-bold">Nº de O.C.</label>
                        <input
                            className="h-10 px-3 w-full rounded bg-slate-200"
                            id="numeroOc"
                            placeholder="Ingrese Nº de O.C."
                            type="text"
                            value={numeroOc}
                            onChange={(e) => setNumeroOc(e.target.value)}
                        />
                    </div>
                </div>
                <div className="sm:items-start mt-2">
                    <div className="sm:items-start mt-5 w-1/3 pr-2">
                        <label htmlFor="descripcionServicio" className="block text-gray-600 uppercase font-bold">Descripción del Servicio</label>
                        <input
                            className="py-2 px-3 w-full rounded bg-slate-200"
                            id="descripcionServicio"
                            placeholder="Descripción del Servicio"
                            type="text"
                            value={descripcionServicio}
                            onChange={(e) => setDescripcionServicio(e.target.value)}
                        />
                    </div>
                </div>
                <div className="sm:items-start mt-2">
                    <h3 className="block text-gray-600 uppercase font-bold">Vigencia</h3>
                    <div className='flex w-full'>
                        <div className='w-1/2 p-1'>
                            <label htmlFor="fechainicio" className="block text-gray-600 font-bold">Fecha de inicio</label>
                            <input
                                className="py-2 px-3 w-full rounded bg-slate-200"
                                id="fechainicio"
                                placeholder="Fecha de inicio"
                                type="date"
                                value={fechaInicio}
                                onChange={(e) => setFechaInicio(e.target.value)}
                            />
                            {formik.touched.fechainicio && formik.errors.fechainicio ? (
                                <div className='w-full block mb-2 text-red-500'>
                                    <p>{formik.errors.fechainicio}</p>
                                </div>
                            ) : null}

                        </div>
                        <div className='w-1/2 p-1'>
                            <label htmlFor="fechafin" className="block text-gray-600 font-bold">Fecha de fin</label>
                            <input
                                className="py-2 px-3 w-full rounded bg-slate-200"
                                id="fechafin"
                                placeholder="Fecha de fin"
                                type="date"
                                value={fechaFin}
                                onChange={(e) => setFechaFin(e.target.value)}
                            />
                            {formik.touched.fechafin && formik.errors.fechafin ? (
                                <div className='w-full block mb-2 text-red-500'>
                                    <p>{formik.errors.fechafin}</p>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div className='my-2 w-1/6'>
                        <label htmlFor="sMoneda" className="block text-gray-600 font-bold">Moneda</label>
                        <select className='w-full p-2 rounded bg-slate-200 text-gray-700' id='sMoneda' onChange={(e) => setMoneda(e.target.value)}>
                            {typeof monedas !== "undefined" && (
                                monedas.map(item => (
                                    <option key={item.id} value={item.id}>{item.descripcion + ' - ' + item.abreviatura}</option>
                                )))}
                        </select>
                    </div>

                </div>
                <div className="sm:items-start mt-5">
                    <table className='w-full'>
                        <tr>
                            <td className='w-1/3 border border-black bg-blue-200'>
                                <p className='text-center'>Descripción linea en contrato</p>
                            </td>
                            <td className='w-1/12 border border-black bg-blue-200'>
                                <p className='text-center'>U.M</p>
                            </td>
                            <td className='w-1/6 border border-black bg-blue-200'>
                                <p className='text-center'>Valor unitario</p>
                            </td>
                            <td className='w-1/6 border border-black bg-blue-200'>
                                <p className='text-center'>Coeficiente</p>
                            </td>
                            <td className='w-1/6 border border-black bg-blue-200'>
                                <p className='text-center'></p>
                            </td>

                        </tr>

                        {
                            lineas && (lineas.map((item, index) =>

                                <tr key={index}>
                                    <td className='w-1/3 border border-black'>
                                        <p className='text-center'>{item.descripcion}</p>
                                    </td>
                                    <td className='w-1/12 border border-black'>
                                        <p className='text-center'>{item.nombreunidad}</p>
                                    </td>
                                    <td className='w-1/6 border border-black'>
                                        <p className='text-center'>{item.importe}</p>
                                    </td>
                                    <td className='w-1/6 border border-black'>
                                        <p className='text-center'>{item.nombrecoeficiente}</p>
                                    </td>
                                    <td className='w-1/6 border border-black'>
                                        <p className='text-center'>
                                            <button
                                                type='button'
                                                onClick={() => handleDelete(item.index)}
                                                className='bg-red-400 text-white p-1 rounded'
                                            >Eliminar
                                            </button>
                                        </p>
                                    </td>

                                </tr>
                            )
                            )
                        }
                    </table>
                </div>
                <form
                    className="w-full mx-auto rounded-2xl"
                    onSubmit={formik.handleSubmit}
                >
                    <div className='flex'>
                        <div className="sm:items-start mt-5 w-1/3 pr-2">
                            <label htmlFor="descripcion" className="block text-gray-600 uppercase font-bold">Descripción</label>
                            <input
                                className="py-2 px-3 w-full rounded bg-slate-200"
                                id="descripcion"
                                placeholder="Descripción"
                                type="text"
                                value={formik.values.descripcion}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.descripcion && formik.errors.descripcion ? (
                                <div className='w-full block mb-2 text-red-500'>
                                    <p>{formik.errors.descripcion}</p>
                                </div>
                            ) : null}
                        </div>
                        <div className="sm:items-start mt-5 w-1/12 pr-2">
                            <label htmlFor="sUnidad" className="block text-gray-600 uppercase font-bold">U.M.</label>
                            <select className='w-full h-10 px-2 rounded bg-slate-200 text-gray-700' id='sUnidad' onChange={(e) => setUnidad(e.target.value)}>
                                {typeof unidades !== "undefined" && (
                                    unidades.map(item => (
                                        <option key={item.id} value={item.id}>{item.descripcion}</option>
                                    )))}
                            </select>
                        </div>
                        <div className="sm:items-start mt-5 w-1/6 pr-2">
                            <label htmlFor="importe" className="block text-gray-600 uppercase font-bold">Valor unitario</label>
                            <input
                                className="py-2 px-3 w-full rounded bg-slate-200"
                                id="importe"
                                placeholder="Tarifa base"
                                type="text"
                                value={formik.values.importe}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.importe && formik.errors.importe ? (
                                <div className='w-full block mb-2 text-red-500'>
                                    <p>{formik.errors.importe}</p>
                                </div>
                            ) : null}
                        </div>
                        <div className="sm:items-start mt-5 w-1/6 pr-2">
                            <label htmlFor="fecha" className="block text-gray-600 uppercase font-bold">Estructura de Costos</label>
                            <Select
                                options={coeficientes}
                                placeholder="Seleccione Estructura"
                                onChange={(value) => handleSelect(value)}
                                name='sEstructuras'
                            />
                        </div>
                        <div className="w-1/6 rounded-lg px-2 pt-4 my-5">
                            <input
                                type="submit"
                                className="w-full shadow-md p-3 bg-indigo-600 text-gray-100 hover:bg-indigo-400 transition-all uppercase font-bold rounded-lg"
                                value="Agregar linea"
                            />
                        </div>
                    </div>

                </form>
                <div className='w-full'>
                    <SubirImagenes
                        id={idContrato}
                        tipo={tipoImagen}
                        ref={childRef}
                        idHtml={idHtml}
                    />
                </div>
                <div className='w-full flex mt-5'>
                    <button
                        type="button"
                        className={`w-1/3 sm:w-1/4 shadow-md p-3 ml-3 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg ${guardar ? 'bg-blue-500' : 'bg-blue-200'} `}
                        onClick={() => handleGuardar()}
                    >
                        Guardar Contrato
                    </button>
                    <button
                        type="button"
                        className="w-1/3 sm:w-1/4 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                        onClick={() => setOpenAlta(false)}
                    >
                        Salir
                    </button>

                </div>
            </div>
        </div>
    )
}

export default FormContrato