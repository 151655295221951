import React, { useState, useEffect, useContext, useRef } from 'react'
import ContextStates from '../../context/contextStates'
import { useFormik } from "formik"
import axios from 'axios'
import * as Yup from "yup"
import { Url } from '../Url'
import SubirImagenes from '../SubirImagenes'

const FormEstructura = () => {
    const { setOpenAlta, modify, setModify, setMensaje, setTipoMensaje, idEstructura, setIdEstructura } = useContext(ContextStates)

    let tipoImagen = "estructuras";
    let idHtml = "imagenesEstructura";
    const childRef = useRef()

    useEffect(() => {
        if (idEstructura !== 0) {
            childRef.current.handleSubmit();
        }
    }, [idEstructura])

    const formik = useFormik({
        initialValues: {
            nombre: '',
        },
        validationSchema: Yup.object({
            nombre: Yup.string()
                .required("Debe ingresar una nombre")
        }),
        onSubmit: async (valores, { resetForm }) => {
            const formData = new FormData()
            formData.append('nombre', valores.nombre);
            try {
                const response = await axios({
                    url: Url + 'api/estructAlta.php',
                    method: 'POST',
                    data: formData
                })
                setIdEstructura(response.data.results.id)
                setTipoMensaje(1)
                setMensaje("El coeficiente se guardó de manera correcta")

                let auxMod = modify;
                auxMod++;
                setModify(auxMod)
                resetForm({ values: '' });
            } catch (e) {
                console.log(e)
            }
        }
    });



    return (
        <div className='w-full'>
            <div className="transition-all w-full sm:p-3">
                <h2 className='text-3xl uppercase mb-3 text-center'>Nueva Estructura de Costos</h2>
                <form
                    className="w-full mx-auto rounded-2xl"
                    onSubmit={formik.handleSubmit}
                >
                    <div className="sm:items-start mt-5 w-1/3 pr-2">
                        <label htmlFor="nombre" className="block text-gray-600 uppercase font-bold">Nombre</label>
                        <input
                            className="py-2 px-3 w-full rounded bg-slate-200"
                            id="nombre"
                            placeholder="Descripción"
                            type="text"
                            value={formik.values.nombre}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.nombre && formik.errors.nombre ? (
                            <div className='w-full block mb-2 text-red-500'>
                                <p>{formik.errors.nombre}</p>
                            </div>
                        ) : null}
                    </div>
                    <div className='w-full'>
                        <SubirImagenes
                            id={idEstructura}
                            tipo={tipoImagen}
                            ref={childRef}
                            idHtml={idHtml}
                        />
                    </div>

                    <div className="w-full rounded-lg px-2 pt-4 my-5 flex">
                        <input
                            type="submit"
                            className="w-full shadow-md p-3 bg-indigo-600 text-gray-100 hover:bg-indigo-400 transition-all uppercase font-bold rounded-lg"
                            value="Guardar Estructura"
                        />
                        <button
                            type="button"
                            className="w-1/3 sm:w-1/4 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                            onClick={() => setOpenAlta(false)}
                        >
                            Salir
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default FormEstructura