import { useState, useEffect, useContext } from 'react'
import ContextStates from '../context/contextStates'
import axios from 'axios'
import { FaEye, FaPen, FaTrash, FaCheck } from 'react-icons/fa'
import { Url } from './Url'

const ListCertificados = () => {
    const [fechaInicio, setFechaInicio] = useState('')
    const [fechaFin, setFechaFin] = useState('')
    const [bProveedor, setBProveedor] = useState('')
    const [bContrato, setBContrato] = useState('')
    const [bOrdenCompra, setBOrdenCompra] = useState('')
    const [estados, setEstados] = useState([])
    const [estado, setEstado] = useState(0)
    const { certificados, setCertificados, setTipoModal, setOpenVista, setCertSelected, modify, tipoUsrLogin, idUsuario } = useContext(ContextStates)

    useEffect(() => {
        const getCertificados = async () => {
            if (idUsuario !== 0) {
                const formData = new FormData()
                formData.append('fechainicio', fechaInicio)
                formData.append('fechafin', fechaFin)
                formData.append('tipoUsr', tipoUsrLogin)
                formData.append('idusuario', idUsuario)
                formData.append('bContrato', bContrato)
                formData.append('bOrdenCompra', bOrdenCompra)
                formData.append('bProveedor', bProveedor)
                formData.append('estado', estado)
                formData.append('url', Url)
                try {
                    const response = await axios({
                        url: Url + 'api/certificadosList.php',
                        method: 'POST',
                        data: formData,
                    })
                    //console.log(response)
                    setCertificados(response.data.results)
                } catch (e) {
                    console.log(e)
                }
            }
        }
        getCertificados()

    }, [fechaInicio, fechaFin, modify, idUsuario, bContrato, bOrdenCompra, bProveedor, estado])

    useEffect(() => {
        const getEstados = async () => {
            const formData = new FormData()
            formData.append('id', 1)
            try {
                const response = await axios({
                    url: Url + 'api/estadosList.php',
                    method: 'POST',
                    data: formData,
                })
                setEstados(response.data.results)
            } catch (e) {
                console.log(e)
            }
        }
        getEstados()
    }, [])

    const asignarCert = (element) => {
        setCertSelected(element)
    }

    const verCertificado = async (item) => {
        asignarCert(item);
        setTipoModal(21)
        setOpenVista(true);
    }
    const editCertificado = async (item) => {
        asignarCert(item);
        setTipoModal(22)
        setOpenVista(true);
    }

    const aprobarCertificado = async (item) => {
        asignarCert(item);
        setTipoModal(23)
        setOpenVista(true);
    }
    const delCertificado = async (item) => {
        asignarCert(item);
        setTipoModal(28)
        setOpenVista(true);
    }


    return (
        <div>
            <div className='bg-white p-3 rounded-xl mb-5 shadow-lg'>
                {(tipoUsrLogin === '1' || tipoUsrLogin === '3' || tipoUsrLogin === '4') && (
                    <div className='flex'>
                        <div className='w-1/3 p-5'>
                            <label htmlFor="bProveedor" className="block text-gray-600 uppercase font-bold">Proveedor</label>
                            <input
                                className="py-2 px-3 w-full rounded bg-slate-100"
                                id="bProveedor"
                                placeholder="Proveedor"
                                type="text"
                                value={bProveedor}
                                onChange={(e) => setBProveedor(e.target.value)}
                            />
                        </div>
                        <div className='w-1/3 p-5'>
                            <label htmlFor="bContrato" className="block text-gray-600 uppercase font-bold">Nº de Contrato</label>
                            <input
                                className="py-2 px-3 w-full rounded bg-slate-100"
                                id="bContrato"
                                placeholder="Nº Contrato"
                                type="text"
                                value={bContrato}
                                onChange={(e) => setBContrato(e.target.value)}
                            />
                        </div>
                        <div className='w-1/3 p-5'>
                            <label htmlFor="bOrdenCompra" className="block text-gray-600 uppercase font-bold">Nº de O.C.</label>
                            <input
                                className="py-2 px-3 w-full rounded bg-slate-100"
                                id="bOrdenCompra"
                                placeholder="Nº Orden de Compra"
                                type="text"
                                value={bOrdenCompra}
                                onChange={(e) => setBOrdenCompra(e.target.value)}
                            />
                        </div>
                        <div className='w-1/3 p-5'>
                            <label htmlFor="sEstados" className="block text-gray-600 uppercase font-bold">Estado</label>
                            <select className='w-full h-10 px-3 rounded bg-slate-100 text-gray-700' id='sEstados' value={estado} onChange={(e) => setEstado(e.target.value)}>
                                {typeof estados !== "undefined" && (
                                    estados.map(item => (
                                        <option key={item.id} value={item.id}>{item.descripcion}</option>
                                    )))}
                            </select>

                        </div>
                    </div>
                )}
                <div className='flex'>
                    <div className='w-1/2 p-5'>
                        <label htmlFor="fechaInicio" className="block text-gray-600 uppercase font-bold">Fecha de Inicio</label>
                        <input
                            className="py-2 px-3 w-full rounded bg-slate-100"
                            id="fechaInicio"
                            placeholder="Fecha de inicio"
                            type="date"
                            value={fechaInicio}
                            onChange={(e) => setFechaInicio(e.target.value)}
                        />
                    </div>
                    <div className='w-1/2 p-5'>
                        <label htmlFor="fechaFin" className="block text-gray-600 uppercase font-bold">Fecha de Fin</label>
                        <input
                            className="py-2 px-3 w-full rounded bg-slate-100"
                            id="fechaFin"
                            placeholder="Fecha de fin"
                            type="date"
                            value={fechaFin}
                            onChange={(e) => setFechaFin(e.target.value)}
                        />
                    </div>
                </div>
            </div>
            <table className='table-auto w-full border-collapse border-spacing-5 border-slate-400 text-gray-700 rounded-xl mx-auto' >
                <thead>
                    <tr className='uppercase bg-blue-400 text-white'>
                        <th className='border-gray-400 p-1'>Desc. Servicio</th>
                        {tipoUsrLogin !== '2' && (
                            <th className='border-gray-400 p-1'>Proveedor</th>
                        )}
                        <th className='border-gray-400 p-1'>Nº Contrato</th>
                        <th className='border-gray-400 p-1'>O.C.</th>
                        <th className='border-gray-400 p-1'>Nº de Certificado</th>
                        <th className='border-gray-400 p-1'>Fecha Inicio</th>
                        <th className='border-gray-400 p-1'>Fecha Vto.</th>
                        <th className='border-gray-400 p-1'>Estado</th>
                        <th className='border-gray-400 p-1'>Acciones</th>
                    </tr>
                </thead>
                <tbody >
                    {
                        typeof certificados !== 'undefined' && (
                            certificados.map((item, index) => (
                                <tr
                                    key={item.id}
                                    className={`hover:cursor-pointer border-b border-gray-200  ${index % 2 === 0 ? 'bg-white hover:bg-slate-200' : 'bg-slate-100 hover:bg-slate-200'}`}

                                >
                                    <td className='border-gray-400 p-1 text-center'>{item.descripcionservicio}</td>
                                    {tipoUsrLogin !== '2' && (
                                        <td className='border-gray-400 p-1 text-center'>{item.proveedor}</td>
                                    )}
                                    <td className='border-gray-400 p-1 text-center'>{item.numero}</td>
                                    <td className='border-gray-400 p-1 text-center'>{item.numerooc}</td>
                                    <td className='border-gray-400 p-1 text-center'>{item.numerocert}</td>
                                    <td className='border-gray-400 p-1 text-center'>{item.fechai}</td>
                                    <td className='border-gray-400 p-1 text-center'>{item.fechaf}</td>
                                    <td className={`border-gray-400 p-1 text-center ${item.idestado === '1' ? 'bg-yellow-300' : item.idestado === '2' ? 'bg-orange-300' : item.idestado === '3' ? 'bg-blue-300' : item.idestado === '4' ? 'bg-green-300' : 'bg-red-300'}`}>{item.estado}</td>
                                    <td className='border-gray-400 p-1 text-center'>
                                        <button
                                            className='bg-indigo-500 p-2 rounded-md text-white'
                                            onClick={() => verCertificado(item)}
                                        >
                                            <FaEye />
                                        </button>
                                        {tipoUsrLogin === '2' && (item.idestado === '1' || item.idestado === '5') && (
                                            <>
                                                <button
                                                    className='bg-red-500 ml-2 p-2 rounded-md text-white'
                                                    onClick={() => editCertificado(item)}
                                                >
                                                    <FaPen />
                                                </button>
                                                <button
                                                    className='bg-red-400 p-2 rounded-md text-white ml-2'
                                                    onClick={() => delCertificado(item)}
                                                >
                                                    <FaTrash />
                                                </button>
                                            </>
                                        )}
                                        {tipoUsrLogin === '3' && item.idestado === '2' &&
                                            <button
                                                className='bg-green-500 p-2 rounded-md mx-auto text-white ml-2'
                                                onClick={() => verCertificado(item)}
                                            >
                                                <div className='flex'>
                                                    <FaCheck className='text-2xl mr-2' />
                                                    Verificar
                                                </div>
                                            </button>

                                        }
                                        {tipoUsrLogin === '4' && item.idestado === '3' &&
                                            <button
                                                className='bg-green-500 p-2 rounded-md mx-auto text-white ml-2'
                                                onClick={() => verCertificado(item)}
                                            >
                                                <div className='flex'>
                                                    <FaCheck className='text-2xl mr-2' />
                                                    Verificar
                                                </div>
                                            </button>

                                        }
                                    </td>
                                </tr>

                            ))
                        )
                    }
                </tbody>
            </table>
        </div>
    )
}

export default ListCertificados