import React, { useState, useContext, useEffect } from 'react'
import Layout from '../components/Layout';
import ContextStates from '../context/contextStates';
import { AiOutlinePlus, AiOutlineSearch } from 'react-icons/ai'
import ListEstructuras from '../components/listEstructuras';
import { RiFileExcel2Line } from 'react-icons/ri'

const Estructuras = () => {
	const { setOpenAlta, setFormAlta, tipoUsrLogin } = useContext(ContextStates);

	const Alta = (opc) => {
		switch (opc) {
			case 1:
				setFormAlta(1);
				break;
			case 2:
				setFormAlta(2);
				break;
		}
		setOpenAlta(true)
	}
	const Import = () => {
		setFormAlta(21);
		setOpenAlta(true);
	}


	return (
		<Layout
			pagina="Estructuras"
		>
			{tipoUsrLogin === '1' && (
				<div className="w-full flex-1 bg-slate-50">
					<h1 className="mt-10 mb-5 text-gray-400 text-3xl text-center">Estructuras de Costos</h1>
					<div className='w-full mt-2 bg-white'>
						<div className='w-full h-16 py-2 px-10 bg-white flex'>

							<>
								<button
									className='bg-indigo-600 hover:bg-indigo-400 shadow-lg p-3 rounded-md text-white uppercase flex'
									onClick={() => Alta(2)}
								>
									<AiOutlinePlus className='text-2xl mr-2' />
									Nueva estructura
								</button>
								<button
									className='bg-green-600 hover:bg-green-400 shadow-lg ml-5 p-3 rounded-md text-white uppercase flex'
									onClick={() => Import()}
								>
									<p className='text-xl mr-2'>
										<RiFileExcel2Line />
									</p>
									Importar
								</button>
							</>
						</div>
						<div className='w-full h-screen p-10 bg-white overflow-scroll'>
							<ListEstructuras />
						</div>
					</div>
				</div>
			)}
		</Layout>
	)
}

export default Estructuras