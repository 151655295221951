import { useState, useEffect, useContext } from 'react'
import ContextStates from '../context/contextStates';
import { FaFilePdf } from "react-icons/fa6";
import { FaFileAlt } from "react-icons/fa";
import { SiMicrosoftexcel, SiMicrosoftword } from "react-icons/si";
import axios from 'axios';
import { Url } from './Url'


const FDelEstructura = () => {
    const { setOpenVista, estrSelected, setImageSelected, setFormPrev, tipoModal, setTipoModal, setTipoMensaje, setMensaje, modify, setModify } = useContext(ContextStates);
    const [coeficientes, setCoeficientes] = useState([])

    const handleDownload = (file, fileName) => {
        fetch(file)
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement("a");
                link.href = url;
                link.download = fileName || "downloaded-file";
                document.body.appendChild(link);

                link.click();

                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
            .catch((error) => {
                console.error("Error fetching the file:", error);
            });
    };

    const handleImageSelected = (imagen) => {
        setImageSelected(imagen)
        setFormPrev(tipoModal)
        setTipoModal(30)
    }


    useEffect(() => {
        const cargarArray = () => {
            let i = 0;
            let rData = [];
            estrSelected.coeficientes.forEach(element => {
                rData.push({
                    "index": i,
                    "idcoef": element.idcoef,
                    "fechainicio": element.fechainicio,
                    "fechafin": element.fechafin,
                    "coeficiente": element.coeficiente,
                    "eliminado": 0
                }
                )
                i++;
            });
            setCoeficientes(rData)
        }
        cargarArray()
    }, [])

    const handleDelete = async () => {
        const formData = new FormData()
        formData.append('idestructura', estrSelected.id);
        try {
            const response = await axios({
                url: Url + 'api/estructDel.php',
                'Content-Type': 'application/json',
                method: 'POST',
                data: formData,
                dataType: 'JSON',
            })
            setTipoMensaje(1)
            setMensaje("Se eliminó la estructura")
            let auxMod = modify;
            auxMod++;
            setModify(auxMod)
            setOpenVista(false)
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div className='mt-10 mb-10 px-10'>
            <p className='text-xl text-gray-600'><span className='font-semibold'>Estructura de Costos: </span>{estrSelected.nombre}</p>
            <div className="sm:items-start mt-5">
                <table className='w-full'>
                    <thead>
                        <tr>
                            <td className='w-1/3 border border-black bg-blue-200'>
                                <p className='text-center'>Fecha de inicio</p>
                            </td>
                            <td className='w-1/6 border border-black bg-blue-200'>
                                <p className='text-center'>Fecha de fin</p>
                            </td>
                            <td className='w-1/6 border border-black bg-blue-200'>
                                <p className='text-center'>Coeficiente</p>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            coeficientes && (coeficientes.map((item, index) =>
                                item.eliminado === 0 &&
                                <tr key={index}>
                                    <td className='w-1/3 border border-black'>
                                        <p className='text-center'>{item.fechainicio}</p>
                                    </td>
                                    <td className='w-1/12 border border-black'>
                                        <p className='text-center'>{item.fechafin}</p>
                                    </td>
                                    <td className='w-1/6 border border-black'>
                                        <p className='text-center'>{item.coeficiente}</p>
                                    </td>
                                </tr>
                            )
                            )
                        }
                    </tbody>
                </table>
            </div>
            <div className='flex'>
                {estrSelected.archivos && estrSelected.archivos.map((item, index) => (
                    item.tipo === 1 ? (
                        <button
                            key={index}
                            onClick={() => handleImageSelected(item.archivo)}
                        >
                            <img src={Url + item.archivo} className='object-cover rounded-xl mt-3 mx-2 h-32' />
                        </button>
                    ) : (
                        item.tipo === 2 ? (
                            <a href={Url + item.archivo} target="_blank" rel="noreferrer" id={12}>
                                <button className='bg-blue-100 rounded-xl mt-3 mx-2 text-blue-600 flex text-center text-5xl p-5' type='button'><FaFilePdf className='mt-1 ml-4 mr-2' /></button>
                            </a>
                        ) :
                            <button
                                className='bg-blue-100 rounded-xl mt-3 mx-2 text-blue-600 flex text-center text-5xl p-5'
                                type='button'
                                key={index}
                                onClick={() => handleDownload(Url + item.archivo, item.archivo)}
                            >
                                {
                                    item.tipo === 3 ? (
                                        <SiMicrosoftexcel className='mt-1 ml-4 mr-2' />)
                                        : item.tipo === 4 ? (
                                            <SiMicrosoftword className='mt-1 ml-4 mr-2' />)
                                            : (<FaFileAlt className='mt-1 ml-4 mr-2' />)
                                }
                            </button>
                    )
                ))}
            </div>
            <div className="w-full rounded-lg mt-10 px-3 flex justify-center">
                <button
                    type="button"
                    className={`w-1/3 sm:w-1/4 shadow-md p-3 ml-3 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg bg-red-500`}
                    onClick={() => handleDelete()}
                >
                    Eliminar Estructura
                </button>
                <button
                    type="button"
                    className="w-1/4 sm:w-1/6 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                    onClick={() => setOpenVista(false)}
                >
                    Salir
                </button>
            </div>
        </div>
    )
}

export default FDelEstructura