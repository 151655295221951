import { useState, useContext, useRef } from 'react'
import ContextStates from '../context/contextStates';
import { FaPrint } from 'react-icons/fa'
import axios from 'axios';
import { Url } from './Url'
import { pdfjs } from 'react-pdf';

import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'

import { FaFilePdf } from "react-icons/fa6";
import { FaFileAlt } from "react-icons/fa";
import { SiMicrosoftexcel, SiMicrosoftword } from "react-icons/si";
import ListImputaciones from './listImputaciones';
import FormImputaciones from './formImputaciones';

/*pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.js',
    import.meta.url,
).toString();*/

const FViewCertificado = () => {
    const [observaciones, setObservaciones] = useState('')
    const { setOpenVista, certSelected, tipoUsrLogin, setMensaje, setTipoMensaje, modify, setModify, setImageSelected, tipoModal, setTipoModal, setFormPrev, idUsuario } = useContext(ContextStates);

    const componentRef = useRef();

    const handleAction = async (action) => {
        const formData = new FormData()
        formData.append('idcertificado', certSelected.id)
        formData.append('accion', action)
        formData.append('observaciones', observaciones)
        formData.append('tipousuario', tipoUsrLogin)
        formData.append('idusuario', idUsuario)
        try {
            const response = await axios({
                url: Url + 'api/certAccion.php',
                method: 'POST',
                data: formData,
            })
            let aux = modify;
            aux++;
            setModify(aux)
            setOpenVista(false)
        } catch (e) {
            console.log(e)
        }
    }

    const handleDownload = (file, fileName) => {
        fetch(file)
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement("a");
                link.href = url;
                link.download = fileName || "downloaded-file";
                document.body.appendChild(link);

                link.click();

                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
            .catch((error) => {
                console.error("Error fetching the file:", error);
            });
    };

    const handleImageSelected = (imagen) => {
        setImageSelected(imagen)
        setFormPrev(tipoModal)
        setTipoModal(30)
    }


    const genPdf = () => {
        const doc = new jsPDF();
        doc.setFont(undefined, 'bold');
        doc.text(`CERTIFICADO Nº ${certSelected.numerocert}`, 100, 10, 'center')
        doc.setFontSize(10)
        doc.setFont(undefined, 'bold');
        doc.text(`PROVEEDOR: `, 10, 20, 'left');
        doc.setFont(undefined, 'normal');
        doc.text(`${certSelected.proveedor}`, 35, 20, 'left');
        doc.setFont(undefined, 'bold');
        doc.text(`Nº DE CONTRATO: `, 10, 25, 'left');
        doc.setFont(undefined, 'normal');
        doc.text(`${certSelected.numero}`, 43, 25, 'left');
        doc.setFont(undefined, 'bold');
        doc.text(`Nº DE O.C.: `, 10, 30, 'left');
        doc.setFont(undefined, 'normal');
        doc.text(`${certSelected.numerooc}`, 30, 30, 'left');
        doc.setFont(undefined, 'bold');
        doc.text(`DESCRIPCIÓN DEL SERVICIO:`, 10, 35, 'left');
        doc.setFont(undefined, 'normal');
        doc.text(`${certSelected.descripcionservicio}`, 64, 35, 'left');
        doc.setFont(undefined, 'bold');
        doc.text(`FECHA DE INICIO: `, 10, 40, 'left');
        doc.setFont(undefined, 'normal');
        doc.text(`${certSelected.fechai}`, 42, 40, 'left');
        doc.setFont(undefined, 'bold');
        doc.text(`FECHA DE FIN: `, 10, 45, 'left');
        doc.setFont(undefined, 'normal');
        doc.text(`${certSelected.fechaf}`, 37, 45, 'left');
        let apr = ''
        if (certSelected.idestado === '3')
            apr = 'por el Supervisor ' + certSelected.supervisor + ' desde el día ' + certSelected.fechaestado;
        doc.setFont(undefined, 'bold');
        doc.text(`ESTADO: `, 10, 50, 'left');
        doc.setFont(undefined, 'normal');
        doc.text(`${certSelected.estado} ${apr}`, 28, 50, 'left');
        let posY = 55;
        if (certSelected.idestado === '4') {
            doc.setFont(undefined, 'bold');
            doc.text(`APROBADO POR `, 10, posY, 'left');
            doc.setFont(undefined, 'normal');
            doc.text(`${certSelected.usuarioestado}`, 41, posY, 'left');
            posY = posY + 5;
        }
        if (certSelected.idestado === '2') {
            doc.setFont(undefined, 'bold');
            doc.text(`SUPERVISOR A CARGO: `, 10, posY, 'left');
            doc.setFont(undefined, 'normal');
            doc.text(`${certSelected.supervisor}`, 45, posY, 'left');
            posY = posY + 5;
        }

        doc.setFont(undefined, 'bold');
        doc.text(`MONEDA: `, 10, posY, 'left');
        doc.setFont(undefined, 'normal');
        doc.text(`${certSelected.moneda}`, 29, posY, 'left');
        posY = posY + 5;
        doc.autoTable({ html: '#tableItems', margin: { top: posY, left: 10, right: 10 } })
        if (certSelected.estados.length > 0) {
            posY = posY + 20 + (certSelected.lineas.length * 20)
        }
        doc.setFontSize(15)
        console.log("imputaciones: ",certSelected.imputaciones)
        if (typeof certSelected.imputaciones!=='undefined') {
            doc.text(`IMPUTACIONES CONTABLES`, 100, posY, 'center')
            posY = posY + 5;
            doc.autoTable({ html: '#tableImputaciones', startY:posY, margin: { left: 10, right: 10 } })
            posY = posY + (certSelected.imputaciones.length * 20)
        }
        doc.text(`OBSERVACIONES`, 100, posY, 'center')
        //posY=posY+10;
        doc.setFontSize(10)
        certSelected.estados.map((item) => {
            doc.setFont(undefined, 'bold');
            posY = posY + 5;
            doc.text(`${item.fecha} - ${item.usuario}:`, 10, posY, 'left');
            posY = posY + 5;
            item.observaciones.map((linea) => {
                doc.setFont(undefined, 'normal');
                doc.text(`${linea.linea}`, 10, posY, 'left');
                posY = posY + 5;
            })
        })
        doc.save(`Certificado_${certSelected.numerocert}.pdf`)
    }

    return (
        <div className='mt-10 mb-10 px-10'>
            <div id="htmPdf" className='print:p-5' ref={componentRef}>
                <h2 className='text-2xl mt-2 text-center font-semibold text-gray-600'>Certificado Nº {certSelected.numerocert}</h2>
                <div className='w-full bg-blue-100 rounded p-3'>
                    <p className='text-xl mt-2 text-gray-600'><span className='font-semibold'>Proveedor: </span>{certSelected.proveedor}</p>
                    <p className='text-xl mt-2 text-gray-600'><span className='font-semibold'>Nº de Contrato: </span>{certSelected.numero}</p>
                    <p className='text-xl mt-2 text-gray-600'><span className='font-semibold'>Nº de O.C.: </span>{certSelected.numerooc}</p>
                    <p className='text-xl mt-2 text-gray-600'><span className='font-semibold'>Descripción del Servicio: </span>{certSelected.descripcionservicio}</p>
                </div>
                <div className='w-full mt-3 bg-blue-100 rounded p-3'>
                    <p className='text-xl mt-2 text-gray-600'><span className='font-semibold'>Fecha Inicio: </span>{certSelected.fechai}</p>
                    <p className='text-xl mt-2 text-gray-600'><span className='font-semibold'>Fecha Fin: </span>{certSelected.fechaf}</p>
                    <p className='text-xl mt-2 text-gray-600'><span className='font-semibold'>Estado: </span>{certSelected.estado} <span className='font-semibold'>{certSelected.idestado === '3' && 'por el Supervisor ' + certSelected.supervisor}</span><span className='font-semibold ml-3'>{'desde el día ' + certSelected.fechaestado}</span></p>
                    {certSelected.idestado === '4' && (
                        <p className='text-xl mt-2 text-gray-600'><span className='font-semibold'>Aprobado por </span>{certSelected.usuarioestado} (Gte.) y {certSelected.supervisor} (Supervisor)</p>
                    )}
                    {certSelected.idestado === '2' && (
                        <p className='text-xl mt-2 text-gray-600'><span className='font-semibold'>Supervisor a cargo: </span><span>{certSelected.supervisor}</span></p>
                    )}
                    <p className='text-xl mt-2 text-gray-600'><span className='font-semibold'>Moneda: </span>{certSelected.moneda}</p>
                </div>
                <div className='w-full mx-auto'>
                    <table id='tableItems' className='w-full mt-4'>
                        <thead>
                            <tr>
                                <th className='w-1/3 border border-black bg-blue-200'>
                                    <p className='text-center'>Descripción de linea</p>
                                </th>
                                <th className='w-1/12 border border-black bg-blue-200'>
                                    <p className='text-center'>U.M.</p>
                                </th>
                                <th className='w-1/12 border border-black bg-blue-200'>
                                    <p className='text-center'>Cantidad</p>
                                </th>
                                <th className='w-1/6 border border-black bg-blue-200'>
                                    <p className='text-center'>Valor unitario</p>
                                </th>
                                <th className='w-1/12 border border-black bg-blue-200'>
                                    <p className='text-center'>Ajuste</p>
                                </th>
                                <th className='w-1/6 border border-black bg-blue-200'>
                                    <p className='text-center'>Valor unitario (Actualizado)</p>
                                </th>
                                <th className='w-1/6 border border-black bg-blue-200'>
                                    <p className='text-center'>Total</p>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {certSelected.lineas && certSelected.lineas.map((item, index) => (
                                <tr key={index}>
                                    <td className='w-1/3 border border-black'>
                                        <p className='text-center'>{item.descripcion}</p>
                                    </td>
                                    <td className='w-1/12 border border-black'>
                                        <p className='text-center'>{item.unidad}</p>
                                    </td>
                                    <td className='w-1/12 border border-black'>
                                        <p className='text-center'>{item.cantidad}</p>
                                    </td>
                                    <td className='w-1/6 border border-black'>
                                        <p className='text-right pr-2'>{item.tarifabase.toLocaleString("es-AR", { maximumFractionDigits: 2 })}</p>
                                    </td>
                                    <td className='w-1/12 border border-black'>
                                        <p className='text-center'>{item.coeficiente !== 1 ? item.coeficiente : '-'}</p>
                                    </td>
                                    <td className='w-1/6 border border-black'>
                                        <p className='text-right pr-2'>{item.valoractualizado.toLocaleString("es-AR", { maximumFractionDigits: 2 })}</p>
                                    </td>
                                    <td className='w-1/6 border border-black'>
                                        <p className='text-right pr-2'>{item.total.toLocaleString("es-AR", { maximumFractionDigits: 2 })}</p>
                                    </td>
                                </tr>)
                            )}
                            <tr>
                                <td className='w-5/6 border border-black' colSpan={6}>
                                    <p className='text-center font-bold uppercase'>Total</p>
                                </td>
                                <td className='w-1/6 border border-black'>
                                    <p className='text-right pr-2 font-bold'>{certSelected.totalgral.toLocaleString("es-AR", { maximumFractionDigits: 2 })}</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='flex print:invisible'>
                    {certSelected.archivos && certSelected.archivos.map((item, index) => (
                        item.tipo === 1 ? (
                            <button
                                key={index}
                                onClick={() => handleImageSelected(item.archivo)}
                            >
                                <img src={Url + item.archivo} className='object-cover rounded-xl mt-3 mx-2 h-32' />
                            </button>
                        ) : (
                            item.tipo === 2 ? (
                                <a href={Url + item.archivo} target="_blank" rel="noreferrer" id={12}>
                                    <button className='bg-blue-100 rounded-xl mt-3 mx-2 text-blue-600 flex text-center text-5xl p-5' type='button'><FaFilePdf className='mt-1 ml-4 mr-2' /></button>
                                </a>
                            ) :
                                <button
                                    className='bg-blue-100 rounded-xl mt-3 mx-2 text-blue-600 flex text-center text-5xl p-5'
                                    type='button'
                                    key={index}
                                    onClick={() => handleDownload(Url + item.archivo, item.archivo)}
                                >
                                    {
                                        item.tipo === 3 ? (
                                            <SiMicrosoftexcel className='mt-1 ml-4 mr-2' />)
                                            : item.tipo === 4 ? (
                                                <SiMicrosoftword className='mt-1 ml-4 mr-2' />)
                                                : (<FaFileAlt className='mt-1 ml-4 mr-2' />)
                                    }
                                </button>
                        )
                    ))}
                </div>
                {(tipoUsrLogin === '3' || tipoUsrLogin === '4') ? (
                    <div className='w-full'>
                        <ListImputaciones view={0} />
                        <FormImputaciones />
                    </div>
                ) : (
                    <div className='w-full'>
                        <ListImputaciones />
                    </div>
                )}
                {
                    certSelected.estados.length > 0 && (
                        <h3 className='text-xl uppercase text-center mt-4'>Observaciones</h3>
                    )
                }{
                    typeof certSelected.estados !== 'undefined' && (

                        certSelected.estados.map((item, index) => (
                            <div key={index} className='w-full mb-2 bg-orange-100 p-2 rounded'>
                                <p><span className='font-semibold'>({item.fecha}) - {item.usuario}: </span></p>
                                {item.observaciones.map((linea, idx) => (
                                    <p key={idx} className='w-full'>{linea.linea}</p>
                                ))}
                            </div>
                        ))
                    )
                }
                {(tipoUsrLogin === '3' || tipoUsrLogin === '4') && (
                    <div className='w-full'>
                        <label htmlFor="observaciones" className="block text-gray-600 uppercase font-bold">Observaciones</label>
                        <textarea
                            className="py-2 px-3 w-full rounded  border border-gray-300"
                            id="observaciones"
                            placeholder="Agregue alguna observación en caso de ser necesario"
                            value={observaciones}
                            onChange={(e) => setObservaciones(e.target.value)}
                        >
                        </textarea>
                    </div>
                )}
            </div>
            <div className="w-full rounded-lg mt-10 px-3 flex justify-center">
                {
                    tipoUsrLogin === '3' && certSelected.idestado === '2' && (
                        <>
                            <button
                                type="button"
                                className={`w-1/3 sm:w-1/4 shadow-md p-3 ml-3 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg bg-blue-700`}
                                onClick={() => handleAction(1)}
                            >
                                Aprobar Certificado
                            </button>
                            <button
                                type="button"
                                className={`w-1/3 sm:w-1/4 shadow-md p-3 ml-3 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg bg-red-500`}
                                onClick={() => handleAction(2)}
                            >
                                Rechazar Certificado
                            </button>

                        </>
                    )
                }
                {
                    tipoUsrLogin === '4' && certSelected.idestado === '3' && (
                        <>
                            <button
                                type="button"
                                className={`w-1/3 sm:w-1/4 shadow-md p-3 ml-3 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg bg-blue-700`}
                                onClick={() => handleAction(1)}
                            >
                                Confirmar Aprobación
                            </button>
                            <button
                                type="button"
                                className={`w-1/3 sm:w-1/4 shadow-md p-3 ml-3 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg bg-red-500`}
                                onClick={() => handleAction(2)}
                            >
                                Rechazar Certificado
                            </button>

                        </>
                    )
                }
                {certSelected.idestado === '4' &&
                    <>
                        <button
                            type="button"
                            className="w-1/3 sm:w-1/4 flex shadow-md p-3 ml-3 bg-indigo-500 text-white hover:bg-indigo-400 transition-all uppercase font-bold rounded-lg"
                            onClick={() => genPdf()}
                        >
                            <FaPrint className='mx-auto mt-1' />
                            <p className='mx-auto text-center'>
                                Imprimir Certificado
                            </p>
                        </button>
                    </>
                }
                <button
                    type="button"
                    className="w-1/4 sm:w-1/6 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                    onClick={() => setOpenVista(false)}
                >
                    Salir
                </button>
            </div>

        </div>
    )
}

export default FViewCertificado