import { useContext, useEffect } from 'react'
import ContextStates from '../context/contextStates';

const Alerta = () => {
    const AuthContext = useContext(ContextStates);
    const { mensaje, setMensaje, tipoMensaje, notificationF } = AuthContext;

    useEffect(()=>{
        const showMensaje = () => {
            if (mensaje!==""){
                setTimeout(() => { 
                    setMensaje("");
                }, 3000)
            }
        }
        showMensaje()
    },[mensaje])


    return (
        <>
        { mensaje!=="" && (
        <div className='absolute w-full'>
            <div className={`uppercase text-bold text-center p-3 w-1/2 mx-auto rounded-xl z-50 ${tipoMensaje===1 ? 'bg-green-300 text-green-700':'bg-orange-300 text-orange-700'}`}>
                {mensaje}
            </div>
        </div>
        )}
        </>
    );
}
 
export default Alerta;