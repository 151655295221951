import React, { useContext } from 'react'
import ContextStates from '../context/contextStates';
import { Url } from './Url'

const FViewImagen = () => {
    const { setTipoModal, formPrev, imageSelected } = useContext(ContextStates);

    
    return (
        <div className='mt-10 mb-10 px-10 mx-auto'>            
            <div className='w-1/2 mx-auto'>
                <img
                    className='w-full object-cover'
                    src={Url+imageSelected}
                />
            </div>
            <div className="w-full rounded-lg mt-10 px-3 flex justify-center">
                <button
                    type="button"
                    className="w-1/4 sm:w-1/6 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                    onClick={() => setTipoModal(formPrev)}
                >
                    Salir
                </button>
            </div>
        </div>
    )
}

export default FViewImagen