import React, { useEffect, useContext } from 'react'
import axios from 'axios';
import ContextStates from '../context/contextStates';
import { Url } from './Url';
import TablaUsuarios from './tables/TablaUsuarios';

const ListUsuarios = ({ tipoUsuarios }) => {
    const {
        buscarUsuario,
        usuarios,
        setUsuarios,
        idUsuario, 
        modify } = useContext(ContextStates);

    useEffect(() => {
        const consultaUsuarios = async () => {
            const formData = new FormData()
            formData.append('tipo', tipoUsuarios)
            formData.append('busqueda', buscarUsuario)
            formData.append('idusuario', idUsuario)
            try {
                const response = await axios({
                    url: Url + 'api/usuariosListado.php',
                    method: 'POST',
                    data: formData,
                })
                setUsuarios(response.data.results);
            } catch (e) {
                console.log(e)
            }
        }
        consultaUsuarios()
    }, [modify, buscarUsuario])


    return (
        <div>
            {typeof usuarios !== 'undefined' && (<TablaUsuarios usuarios={usuarios} />)}
        </div>
    )
}

export default ListUsuarios