import React, { useState, useContext, useEffect } from 'react'
import Layout from '../components/Layout';
import ContextStates from '../context/contextStates';
import { AiOutlinePlus, AiOutlineSearch } from 'react-icons/ai'
import ListCertificados from '../components/listCertificados';

const Certificados = () => {
	const [tab, setTab] = useState(1);
	const { setOpenAlta, setFormAlta, tipoUsrLogin } = useContext(ContextStates);

	const Alta = (opc) => {
		switch (opc) {
			case 1:
				setFormAlta(1);
				break;
			case 2:
				setFormAlta(2);
				break;
		}
		setOpenAlta(true)
	}


	return (
		<Layout
			pagina="Certificados"
		>
			<div className="w-full flex-1 bg-slate-50">
				<h1 className="mt-10 mb-5 text-gray-400 text-3xl text-center">Certificados</h1>

				<div className='w-full mt-2 bg-white'>
					<div className='w-full h-screen p-10 bg-white overflow-scroll'>
						<ListCertificados />
					</div>
				</div>
			</div>
		</Layout>
	)
}

export default Certificados