import React, { useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import Layout from '../components/Layout';
import ContextStates from '../context/contextStates';
import { AiOutlinePlus, AiOutlineSearch } from 'react-icons/ai'
import ListUsuarios from '../components/listUsuarios';
import { RiFileExcel2Line } from 'react-icons/ri'

const AdminUsuarios = () => {
	const { setOpenAlta, setFormAlta, buscarUsuario, setBuscarUsuario, setTipoUsuario, tipoUsrLogin, roles } = useContext(ContextStates);

	const Alta = () => {
		setTipoUsuario(0);
		setFormAlta(5);
		setOpenAlta(true);
	}

	const Import = () => {
		setFormAlta(12);
		setOpenAlta(true);
	}


	return (
		<Layout
			pagina="Usuarios"
		>
			{tipoUsrLogin === '1' && (
				<div className="w-full flex-1 bg-slate-50">
					<h1 className="mt-10 mb-5 text-gray-400 text-3xl text-center">Administración de usuarios</h1>
					<div className='w-full mt-2 bg-white'>
						<div className='w-full h-16 py-2 px-10 bg-white md:flex mb-4'>
							<button
								className='bg-indigo-600 hover:bg-indigo-400 p-3 rounded-md text-white uppercase flex mb-2 md:mb-0'
								onClick={() => Alta(true)}
							>
								<AiOutlinePlus className='text-2xl mr-2' />
								Agregar Usuario
							</button>
							<div className='py-3 rounded-3xl bg-slate-50 w-full md:w-1/3 mx-auto px-3 flex'>
								<input
									type="text"
									className='w-11/12 outline-none bg-slate-50'
									value={buscarUsuario}
									placeholder="Buscar Usuario"
									onChange={(e) => setBuscarUsuario(e.target.value)}
								/>
								<AiOutlineSearch className='ml-auto text-2xl' />
							</div>
						</div>
						<div className='w-full h-full p-10'>
							<ListUsuarios />
						</div>
					</div>
				</div>
			)}
		</Layout>
	)
}

export default AdminUsuarios