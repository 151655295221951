import { useState, useEffect, useContext } from 'react'
import ContextStates from '../context/contextStates'
import axios from 'axios'
import { FaTrash } from 'react-icons/fa'
import { Url } from './Url'


const FormImputaciones = () => {
    const [cuenta, setCuenta] = useState("")
    const [cc, setCc] = useState("")
    const [afe, setAfe] = useState("")
    const [importe, setImporte] = useState("")
    const [activo, setActivo] = useState(false)

    const { certSelected, setModify, modify, idUsuario } = useContext(ContextStates)

    useEffect(() => {
        const checkActivo = () => {
            if (cuenta !== '' && cc !== '' && afe !== '' && importe !== '')
                setActivo(true)
            else
                setActivo(false)
        }
        checkActivo()
    }, [cuenta, cc, afe, importe])

    const addImputacion = async () => {
        if (importe !== "") {
            const formData = new FormData()
            formData.append('id', certSelected.id);
            formData.append('cuenta', cuenta);
            formData.append('cc', cc);
            formData.append('afe', afe);
            formData.append('importe', importe);
            formData.append('idusuario', idUsuario);
            try {
                const response = await axios({
                    url: Url + 'api/addImputacion.php',
                    method: 'POST',
                    data: formData,
                })
                let aux = modify + 1;
                setModify(aux)
                setCuenta('');
                setCc('');
                setAfe('');
                setImporte('');
            } catch (e) {
                console.log(e)
            }
        }
    }

    return (
        <div className='w-full mb-10 bg-blue-100 rounded-b-lg'>
            <div className='flex'>
                <div className='w-1/5 m-2'>
                    <label htmlFor="cuenta" className="block text-gray-600 font-bold">Cuenta</label>
                    <input
                        className={`py-2 px-3 w-full rounded bg-white border border-gray-300`}
                        id="cuenta"
                        placeholder="Cuenta"
                        type="text"
                        value={cuenta}
                        onChange={(e) => setCuenta(e.target.value)}
                    />
                </div>
                <div className='w-1/5 m-2'>
                    <label htmlFor="cc" className="block text-gray-600 font-bold">CC</label>
                    <input
                        className={`py-2 px-3 w-full rounded bg-white border border-gray-300`}
                        id="CC"
                        placeholder="cc"
                        type="text"
                        value={cc}
                        onChange={(e) => setCc(e.target.value)}
                    />
                </div>
                <div className='w-1/5 m-2'>
                    <label htmlFor="afe" className="block text-gray-600 font-bold">AFE</label>
                    <input
                        className={`py-2 px-3 w-full rounded bg-white border border-gray-300`}
                        id="afe"
                        placeholder="AFE"
                        type="text"
                        value={afe}
                        onChange={(e) => setAfe(e.target.value)}
                    />
                </div>
                <div className='w-1/5 m-2'>
                    <label htmlFor="importe" className="block text-gray-600 font-bold">Importe</label>
                    <input
                        className={`py-2 px-3 w-full rounded bg-white border border-gray-300`}
                        id="importe"
                        placeholder="Importe"
                        type="text"
                        value={importe}
                        onChange={(e) => setImporte(e.target.value)}
                    />
                </div>
                <div className='w-1/5 m-2'>
                    <button
                        type='button'
                        className={`text-white w-full p-3 rounded-xl mt-4 uppercase ${activo ? 'bg-blue-600' : 'bg-gray-300'}`}
                        onClick={() => addImputacion()}
                        disabled={!activo}
                    >Agregar Imputación Contable</button>
                </div>
            </div>

        </div>
    )
}

export default FormImputaciones