import { Fragment, useRef, useState, useContext } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import ContextStates from '../context/contextStates';
import FormUsuario from './forms/formUsuario';
import FormImportUsuarios from './forms/FormImportUsuarios';
import FormContrato from './forms/formContrato';
import FormEstructura from './forms/formEstructura';
import FormCertificado from './forms/formCertificado';
import FImport from './forms/fImport';

const ModalAlta = ({ form, boton, icon }) => {
	const { formAlta, openAlta, setOpenAlta } = useContext(ContextStates)
	const cancelButtonRef = useRef(null)


	return (
		<>
			<Transition.Root show={openAlta} as={Fragment}>
				<Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpenAlta}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<div className="fixed z-10 inset-0 overflow-y-auto">
						<div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<Dialog.Panel className="relative bg-white rounded-xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 w-11/12">
									<div className="px-4 pt-2 sm:p-6">
										<div className="sm:flex sm:items-start">
											{formAlta === 1 ?
												(
													<FormContrato />
												) : formAlta === 2 ?
												(
													<FormEstructura />
												) : formAlta === 5 ?
												(
													<FormUsuario />
												) : formAlta === 12 ?
												(
													<FormImportUsuarios />
												) : formAlta === 15 ?
												(
													<FormCertificado />
												) : formAlta === 20 ?
												(
													<FImport />
												) : formAlta === 21 ?
												(
													<FImport />
												) : formAlta === 22 ?
												(
													<FImport />
												) : null}
										</div>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>

		</>
	)
}

export default ModalAlta