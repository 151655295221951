import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import ContextStates from '../context/contextStates';
import BarChartGraph from './charts/BarChartGraph';
import { RiFileExcel2Line } from 'react-icons/ri'
import { Url } from './Url';
import * as XLSX from 'xlsx';
import Select from 'react-select';

const FViewReporting = () => {
    const [proveedores, setProvedores] = useState([])
    const [proveedor, setProveedor] = useState(0)
    const [lineasSel, setLineasSel] = useState([])
    const [linea, setLinea] = useState(0)
    const [lineas, setLineas] = useState([])
    const [lineasExcel, setLineasExcel] = useState([])
    const [supervisores, setSupervisores] = useState([])
    const [supervisor, setSupervisor] = useState(0)
    const [estados, setEstados] = useState([])
    const [estado, setEstado] = useState(0)
    const [fechaInicio, setFechaInicio] = useState('')
    const [fechaFin, setFechaFin] = useState('')
    const [rEstados, setREstados] = useState([])
    const [rProveedores, setRProveedores] = useState([])
    const [rPromedios, setRPromedios] = useState([])
    const [valores, setValores] = useState([])


    const { } = useContext(ContextStates);


    useEffect(() => {
        const getProveedores = async () => {
            const formData = new FormData()
            formData.append('id', 1);
            try {
                const response = await axios({
                    url: Url + 'api/provList.php',
                    method: 'POST',
                    data: formData,
                })
                setProvedores(response.data.results)
            } catch (e) {
                console.log(e)
            }
        }
        getProveedores()
    }, [])

    useEffect(() => {
        const getLineas = async () => {
            const formData = new FormData()
            formData.append('idproveedor', proveedor);
            formData.append('fechainicio', fechaInicio);
            formData.append('fechafin', fechaFin);
            try {
                const response = await axios({
                    url: Url + 'api/lineasSel.php',
                    method: 'POST',
                    data: formData,
                })
                setLineasSel(response.data.results)
            } catch (e) {
                console.log(e)
            }
        }
        getLineas()
    }, [proveedor, fechaInicio, fechaFin])

    useEffect(() => {
        const getLineas = async () => {
            const formData = new FormData()
            formData.append('idproveedor', proveedor);
            formData.append('fechainicio', fechaInicio);
            formData.append('fechafin', fechaFin);
            formData.append('linea', linea);
            try {
                const response = await axios({
                    url: Url + 'api/lineasRep.php',
                    method: 'POST',
                    data: formData,
                })
                console.log(response)
                setLineas(response.data.results.lineasView)
                setLineasExcel(response.data.results.lineasExcel)
            } catch (e) {
                console.log(e)
            }
        }
        getLineas()
    }, [proveedor, supervisor, fechaInicio, fechaFin, linea])

    useEffect(() => {
        const getSupervisores = async () => {
            const formData = new FormData()
            formData.append('id', 1);
            try {
                const response = await axios({
                    url: Url + 'api/superList.php',
                    method: 'POST',
                    data: formData,
                })
                setSupervisores(response.data.results)
            } catch (e) {
                console.log(e)
            }
        }
        getSupervisores()
    }, [])

    useEffect(() => {
        const getEstados = async () => {
            const formData = new FormData()
            formData.append('id', 1);
            try {
                const response = await axios({
                    url: Url + 'api/estadosList.php',
                    method: 'POST',
                    data: formData,
                })
                setEstados(response.data.results)
            } catch (e) {
                console.log(e)
            }
        }
        getEstados()
    }, [])

    useEffect(() => {
        const getValores = async () => {
            const formData = new FormData()
            formData.append('proveedor', proveedor);
            formData.append('supervisor', supervisor);
            formData.append('fechainicio', fechaInicio);
            formData.append('fechafin', fechaFin);
            formData.append('estado', estado);
            try {
                const response = await axios({
                    url: Url + 'api/getValores.php',
                    method: 'POST',
                    data: formData,
                })
                setREstados(response.data.results.estados)
                setRProveedores(response.data.results.proveedores)
                setRPromedios(response.data.results.promedios)
            } catch (e) {
                console.log(e)
            }
        }
        getValores()
    }, [proveedor, supervisor, fechaInicio, fechaFin])

    const handleSelect = (val) => {
        setLinea(val.idlinea)
    }

    const handleDownload = (data) => {
        const libro = XLSX.utils.book_new()
        const hoja = XLSX.utils.json_to_sheet(data)
        XLSX.utils.book_append_sheet(libro, hoja, "Titulo");
        setTimeout(() => {
            XLSX.writeFile(libro, "file.xlsx");
        }, 1000);
    }

    return (
        <div className='w-full mt-10 mb-10 px-10'>
            <h1 className='text-center text-gray-500 text-3xl'>Modulo de Reportes</h1>
            <div className='flex'>
                <div className='w-1/2 p-5'>
                    <label htmlFor="fechaInicio" className="block text-gray-600 uppercase font-bold">Fecha de Inicio</label>
                    <input
                        className="py-2 px-3 w-full rounded bg-slate-300"
                        id="fechaInicio"
                        placeholder="Fecha de inicio"
                        type="date"
                        value={fechaInicio}
                        onChange={(e) => setFechaInicio(e.target.value)}
                    />
                </div>
                <div className='w-1/2 p-5'>
                    <label htmlFor="fechaFin" className="block text-gray-600 uppercase font-bold">Fecha de Fin</label>
                    <input
                        className="py-2 px-3 w-full rounded bg-slate-300"
                        id="fechaFin"
                        placeholder="Fecha de fin"
                        type="date"
                        value={fechaFin}
                        onChange={(e) => setFechaFin(e.target.value)}
                    />
                </div>
            </div>
            <div className='my-2 flex'>
                <div className='w-1/3 p-1'>
                    <label htmlFor="sProveedores" className="block text-gray-600 font-bold">Proveedor</label>
                    <select className='w-full h-10 px-3 rounded bg-slate-200 text-gray-700' id='sProveedores' value={proveedor} onChange={(e) => setProveedor(e.target.value)}>
                        {typeof proveedores !== "undefined" && (
                            proveedores.map(item => (
                                <option key={item.id} value={item.id}>{item.nombre}</option>
                            )))}
                    </select>
                </div>
                <div className='w-1/3 p-1'>
                    <label htmlFor="sSupervisores" className="block text-gray-600 font-bold">Supervisor a cargo</label>
                    <select className='w-full h-10 px-3 rounded bg-slate-200 text-gray-700' id='sSupervisores' value={supervisor} onChange={(e) => setSupervisor(e.target.value)}>
                        {typeof supervisores !== "undefined" && (
                            supervisores.map(item => (
                                <option key={item.id} value={item.id}>{item.nombre}</option>
                            )))}
                    </select>
                </div>
                <div className='w-1/3 p-1'>
                    <label htmlFor="sEstados" className="block text-gray-600 font-bold">Estado</label>
                    <select className='w-full h-10 px-3 rounded bg-slate-200 text-gray-700' id='sEstados' value={estado} onChange={(e) => setEstado(e.target.value)}>
                        {estados && (
                            estados.map(item => (
                                <option key={item.id} value={item.id}>{item.descripcion}</option>
                            )))}
                    </select>
                </div>
            </div>
            <div className='w-full flex'>
                <div className='w-full py-5 px-3 md:px-10 bg-slate-100 rounded-xl'>
                    <div className='w-full h-full bg-white'>
                        <h4 className='text-black text-center uppercase text-3xl mb-5'>Lineas de contratos</h4>
                        <div className='p-5'>
                            <button
                                type='button'
                                className='mb-3 bg-green-600 hover:bg-green-400 shadow-lg p-3 rounded-md text-white uppercase flex'
                                onClick={() => handleDownload(lineasExcel)}
                            >
                                <p className='text-xl mr-2'>
                                    <RiFileExcel2Line />
                                </p>
                                Exportar a Excel
                            </button>

                            <Select
                                options={lineasSel}
                                placeholder="Seleccione Linea"
                                onChange={(value) => handleSelect(value)}
                                name='sEstructuras'
                            />

                        </div>

                        <table className='table-auto mt-2 w-full border-collapse border-spacing-5 border-slate-400 text-gray-700 rounded-xl mx-auto' >
                            <thead>
                                <tr className='uppercase bg-blue-400 text-white'>
                                    <th className='border-gray-400 p-1'>Proveedor</th>
                                    <th className='border-gray-400 p-1'>Contrato</th>
                                    <th className='border-gray-400 p-1'>Certificado</th>
                                    <th className='border-gray-400 p-1'>OC</th>
                                    <th className='border-gray-400 p-1'>Descripción del Servicio</th>
                                    <th className='border-gray-400 p-1'>Fecha inicio</th>
                                    <th className='border-gray-400 p-1'>Fecha fin</th>
                                    <th className='border-gray-400 p-1'>Descripción</th>
                                    <th className='border-gray-400 p-1'>Cantidad</th>
                                    <th className='border-gray-400 p-1'>Unidad</th>
                                    <th className='border-gray-400 p-1'>Tarifa base</th>
                                    <th className='border-gray-400 p-1'>Ajuste</th>
                                    <th className='border-gray-400 p-1'>Importe</th>
                                    <th className='border-gray-400 p-1'>Total</th>
                                </tr>
                            </thead>
                            <tbody >
                                {
                                    typeof lineas !== 'undefined' && (
                                        lineas.map((item, index) => (
                                            item.value !== 0 &&
                                            <tr
                                                key={item.id}
                                                className={`hover:cursor-pointer border-b border-gray-200  ${index % 2 === 0 ? 'bg-white hover:bg-slate-200' : 'bg-slate-100 hover:bg-slate-200'}`}

                                            >
                                                <td className='border-gray-400 p-1 text-center'>{item.proveedor}</td>
                                                <td className='border-gray-400 p-1 text-center'>{item.contrato}</td>
                                                <td className='border-gray-400 p-1 text-center'>{item.certificado}</td>
                                                <td className='border-gray-400 p-1 text-center'>{item.numerooc}</td>
                                                <td className='border-gray-400 p-1 text-center'>{item.descripcionservicio}</td>
                                                <td className='border-gray-400 p-1 text-center'>{item.fechainicio}</td>
                                                <td className='border-gray-400 p-1 text-center'>{item.fechafin}</td>
                                                <td className='border-gray-400 p-1 text-center'>{item.descripcion}</td>
                                                <td className='border-gray-400 p-1 text-center'>{item.cantidad}</td>
                                                <td className='border-gray-400 p-1 text-center'>{item.unidad}</td>
                                                <td className='border-gray-400 p-1 text-center'>{item.tarifabase.toLocaleString("es-AR", { maximumFractionDigits: 2 })}</td>
                                                <td className='border-gray-400 p-1 text-center'>{item.coef}</td>
                                                <td className='border-gray-400 p-1 text-center'>{item.importe.toLocaleString("es-AR", { maximumFractionDigits: 2 })}</td>
                                                <td className='border-gray-400 p-1 text-center'>{item.total.toLocaleString("es-AR", { maximumFractionDigits: 2 })}</td>
                                            </tr>
                                        ))
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
            <div className='w-full flex mt-10'>
                <div className='w-full md:w-1/3 h-96 py-5 px-3 md:px-10 bg-slate-100 rounded-xl'>
                    <div className='w-full h-full bg-white'>
                        <h4 className='text-black text-center uppercase'>Estados de Certificados</h4>
                        {rEstados &&
                            (
                                <BarChartGraph data={rEstados} graph={1} />
                            )}
                    </div>
                </div>
                <div className='w-full md:w-1/3 h-96 py-5 px-3 md:px-10 bg-slate-100 rounded-xl'>
                    <div className='w-full h-full bg-white'>
                        <h4 className='text-black text-center uppercase'>Certificados por Proveedor</h4>
                        {rProveedores &&
                            (
                                <BarChartGraph data={rProveedores} graph={2} />
                            )}
                    </div>
                </div>
                <div className='w-full md:w-1/3 h-96 py-5 px-3 md:px-10 bg-slate-100 rounded-xl'>
                    <div className='w-full h-full bg-white'>
                        <h4 className='text-black text-center uppercase'>Promedios de tiempos de demora en cada estado</h4>
                        {rPromedios &&
                            (
                                <BarChartGraph data={rPromedios} graph={3} />
                            )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FViewReporting