import React, { useContext } from 'react'
import ContextStates from '../context/contextStates';
import axios from 'axios';
import { Url } from './Url'

const FDelContrato = () => {
    const { setOpenVista, contratoSelected, modify, setModify, setTipoMensaje, setMensaje } = useContext(ContextStates);

    const handleDelete = async () => {
        const formData = new FormData()
        formData.append('idcontrato', contratoSelected.id);
        try {
            const response = await axios({
                url: Url + 'api/contratoDel.php',
                'Content-Type': 'application/json',
                method: 'POST',
                data: formData,
                dataType: 'JSON',
            })
            setTipoMensaje(1)
            setMensaje("Se guardaron las modificaciones")
            let auxMod = modify;
            auxMod++;
            setModify(auxMod)
            setOpenVista(false)
        } catch (e) {
            console.log(e)
        }
    }


    return (
        <div className='mt-10 mb-10 px-10'>
            <p className='text-xl text-gray-600'><span className='font-semibold'>Proveedor: </span>{contratoSelected.proveedor}</p>
            <p className='text-xl text-gray-600'><span className='font-semibold'>Nº de Contrato: </span>{contratoSelected.numero}</p>
            <p className='text-xl text-gray-600'><span className='font-semibold'>Nº de O.C.: </span>{contratoSelected.numeroOc}</p>
            <p className='text-xl mt-4 text-gray-600'><span className='font-semibold'>Fecha Inicio: </span>{contratoSelected.fechainicio}</p>
            <p className='text-xl mt-4 text-gray-600'><span className='font-semibold'>Fecha Fin: </span>{contratoSelected.fechafin}</p>
            <p className='text-xl mt-4 text-gray-600'><span className='font-semibold'>Estado: </span>{contratoSelected.estado}</p>
            <p className='text-xl mt-4 text-gray-600'><span className='font-semibold'>Moneda: </span>{contratoSelected.moneda}</p>
            <div className='w-full mx-auto'>
                <table className='w-full'>
                    <thead>
                        <tr>
                            <td className='w-1/3 border border-black bg-blue-200 text-center'>
                                Descripción linea en contrato
                            </td>
                            <td className='w-1/6 border border-black bg-blue-200 text-center'>
                                U.M
                            </td>
                            <td className='w-1/4 border border-black bg-blue-200 text-center'>
                                Valor unitario
                            </td>
                            <td className='w-1/4 border border-black bg-blue-200 text-center'>
                                Estructura
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {contratoSelected.lineas && contratoSelected.lineas.map((item, index) => (
                            <tr key={index}>
                                <td className='w-1/3 border border-black'>
                                    <p className='text-center'>{item.descripcion}</p>
                                </td>
                                <td className='w-1/6 border border-black'>
                                    <p className='text-center'>{item.unidad}</p>
                                </td>
                                <td className='w-1/4 border border-black'>
                                    <p className='text-center'>{item.tarifabase}</p>
                                </td>
                                <td className='w-1/4 border border-black'>
                                    <p className='text-center'>{item.estructura}</p>
                                </td>
                            </tr>)
                        )}
                    </tbody>
                </table>
            </div>
            <div className='flex'>
                {contratoSelected.imagenes && contratoSelected.imagenes.map((item, index) => (

                    <img src={Url + item.imagen} className='object-cover mt-2 mr-2 h-32' />
                )
                )}
            </div>
            <div className="w-full rounded-lg mt-10 px-3 flex justify-center">
                <button
                    type="button"
                    className={`w-1/3 sm:w-1/4 shadow-md p-3 ml-3 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg bg-red-500`}
                    onClick={() => handleDelete()}
                >
                    Eliminar Contrato
                </button>
                <button
                    type="button"
                    className="w-1/4 sm:w-1/6 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                    onClick={() => setOpenVista(false)}
                >
                    Salir
                </button>
            </div>
        </div>
    )
}

export default FDelContrato