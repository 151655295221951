import { useState, useEffect, useContext, useRef } from 'react'
import ContextStates from '../context/contextStates';
import { useFormik } from "formik"
import axios from 'axios';
import { FaFilePdf } from "react-icons/fa6";
import { FaFileAlt } from "react-icons/fa";
import { FaEye, FaPen, FaTrash, FaCheck } from 'react-icons/fa'
import { SiMicrosoftexcel, SiMicrosoftword } from "react-icons/si";
import SubirImagenes from './SubirImagenes';
import { Url } from './Url'
import * as Yup from "yup"


const FEditEstructura = () => {
    const [nombre, setNombre] = useState('')
    const [errorFecha, setErrorFecha] = useState(false)
    const [coeficientes, setCoeficientes] = useState([])
    const [modificado, setModificado] = useState(false)
    const [guardar, setGuardar] = useState(false)
    const [agregar, setAgregar] = useState(false)
    const { setOpenVista, estrSelected, setMensaje, setTipoMensaje,
        modify, setModify, setImageSelected, setFormPrev,
        tipoModal, setTipoModal,
        idEstructura, setIdEstructura, setFileselected, setSource } = useContext(ContextStates);

    let tipoImagen = "estructuras";
    let idHtml = "imagenesEstructura";
    const childRef = useRef()

    useEffect(() => {
        if (idEstructura !== 0) {
            childRef.current.handleSubmit();
        }
    }, [idEstructura])

    useEffect(() => {
        const cargarArray = () => {
            let i = 0;
            let rData = [];
            estrSelected.coeficientes.forEach(element => {
                rData.push({
                    "index": i,
                    "idcoef": element.idcoef,
                    "fechainicio": element.fechainicio,
                    "fechafin": element.fechafin,
                    "coeficiente": element.coeficiente,
                    "eliminado": 0
                }
                )
                i++;
            });
            setCoeficientes(rData)
        }
        cargarArray()
        setNombre(estrSelected.nombre)
    }, [])

    const handleSetCoeficiente = (e, index) => {
        let rData = [...coeficientes]
        rData.forEach(element => {
            if (element.idcoef === index) {
                element.coeficiente = e
                setModificado(true)
                return
            }
        });
        setCoeficientes(rData);
    }

    const handleDelete = (index) => {
        let rData = [...coeficientes]
        rData.forEach(element => {
            if (element.idcoef === index) {
                element.eliminado = 1;
                setModificado(true)
                return
            }
        });
        setCoeficientes(rData);
    }

    const deleteFile = (file) => {
        setFileselected({
            id: file.id,
            archivo: file.archivo,
            tipo: file.tipo
        })
        setSource(3)
        setFormPrev(tipoModal)
        setTipoModal(40)
    }

    const formik = useFormik({
        initialValues: {
            fechaInicio: '',
            fechaFin: '',
            coeficiente: ''
        },
        validationSchema: Yup.object({
            fechaInicio: Yup.date()
                .required("Debe ingresar una fecha de inicio"),
            fechaFin: Yup.date()
                .required("Debe ingresar una fecha de fin"),
            coeficiente: Yup.number()
                .required("Debe ingresar una cantidad")
        }),
        onSubmit: async (valores, { resetForm }) => {
            if (agregar) {
                let rData = [...coeficientes];
                let index = rData.length + 1;
                rData.push({
                    "idcoef": 0,
                    "index": index,
                    "fechainicio": valores.fechaInicio,
                    "fechafin": valores.fechaFin,
                    "coeficiente": valores.coeficiente,
                    "eliminado": 0
                })
                setCoeficientes(rData)
                setModificado(true)
                resetForm({ values: '' });
            }
        }
    });

    useEffect(() => {
        if (coeficientes.length > 0 && modificado) {
            setGuardar(true)
        }
        else {
            setGuardar(false)
        }

        if (formik.values.coeficiente !== '') {
            if (errorFecha)
                setAgregar(false)
            else
                setAgregar(true)
        }
        else {
            setAgregar(false)
        }

    }, [coeficientes, errorFecha, formik.values.coeficiente])

    useEffect(() => {
        const getLastDayOfMonth = (year, month) => {
            let date = new Date(year, month, 0);
            return date.getDate();
        }

        let encontrado = 0;

        let rData = [...coeficientes]
        rData.forEach(element => {

            if (element.fechainicio === formik.values.fechaInicio) {

                encontrado = 1;
                return
            }
        });

        if (encontrado === 0) {
            setErrorFecha(false)
            const vFechaI = formik.values.fechaInicio.split('-');
            const dia = parseInt(vFechaI[2]);
            const mes = parseInt(vFechaI[1]);
            const anio = parseInt(vFechaI[0]);
            let ultDia = 0;
            let ultMes = 0;
            let ultAnio = 0;
            if (dia === 1) {
                ultDia = getLastDayOfMonth(anio, mes);
                ultMes = mes;
                ultAnio = anio;
            }
            else {
                ultDia = dia - 1;
                if (mes === 12) {
                    ultMes = 1;
                    ultAnio = anio + 1;
                }
                else {
                    ultMes = mes + 1;
                    ultAnio = anio;
                }
            }
            let diaDef = '';
            let mesDef = '';

            if (ultDia < 10)
                diaDef = "0" + ultDia;
            else
                diaDef = ultDia;


            if (ultMes < 10)
                mesDef = "0" + ultMes;
            else
                mesDef = ultMes;

            let fechaF = '';

            fechaF = ultAnio + "-" + mesDef + "-" + diaDef;
            //console.log(fechaF)
            formik.setFieldValue('fechaFin', fechaF);
        }
        else {
            setErrorFecha(true)
        }
    }, [formik.values.fechaInicio])

    const handleGuardar = async () => {
        if (guardar) {
            setIdEstructura(0)
            const auxCoeficientes = JSON.stringify(coeficientes)
            const formData = new FormData()
            formData.append('idestructura', estrSelected.id);
            formData.append('nombre', nombre);
            formData.append('coeficientes', auxCoeficientes);
            try {
                const response = await axios({
                    url: Url + 'api/estructSet.php',
                    'Content-Type': 'application/json',
                    method: 'POST',
                    data: formData,
                    dataType: 'JSON',
                })
                setIdEstructura(response.data.results.id)
                setTipoMensaje(1)
                setMensaje("Se guardaron las modificaciones")
                let auxMod = modify;
                auxMod++;
                setModify(auxMod)
                setOpenVista(false)
            } catch (e) {
                console.log(e)
            }
        }
    }

    const handleImageSelected = (imagen) => {
        setImageSelected(imagen)
        setFormPrev(tipoModal)
        setTipoModal(30)
    }

    const handleDownload = (file, fileName) => {
        fetch(file)
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement("a");
                link.href = url;
                link.download = fileName || "downloaded-file";
                document.body.appendChild(link);

                link.click();

                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
            .catch((error) => {
                console.error("Error fetching the file:", error);
            });
    };

    return (
        <div className='mt-10 mb-10 px-10'>
            <p className='text-xl text-gray-600'><span className='font-semibold'>Estructura de Costos: </span>{estrSelected.nombre}</p>
            <div className='w-1/4 p-1'>
                <label htmlFor="numero" className="block text-gray-600 font-bold">Nombre de la Estructura</label>
                <input
                    className="h-10 px-3 w-full rounded bg-slate-200"
                    id="nombre"
                    placeholder="Ingrese el nombre"
                    type="text"
                    value={nombre}
                    onChange={(e) => setNombre(e.target.value)}
                />
            </div>

            <div className="sm:items-start mt-5">
                <table className='w-full'>
                    <thead>
                        <tr>
                            <td className='w-1/3 border border-black bg-blue-200'>
                                <p className='text-center'>Fecha de inicio</p>
                            </td>
                            <td className='w-1/6 border border-black bg-blue-200'>
                                <p className='text-center'>Fecha de fin</p>
                            </td>
                            <td className='w-1/6 border border-black bg-blue-200'>
                                <p className='text-center'>Coeficiente</p>
                            </td>
                            <td className='w-1/6 border border-black bg-blue-200'>
                                <p className='text-center'></p>
                            </td>

                        </tr>
                    </thead>
                    <tbody>
                        {
                            coeficientes && (coeficientes.map((item, index) =>
                                item.eliminado === 0 &&
                                <tr key={index}>
                                    <td className='w-1/3 border border-black'>
                                        <p className='text-center'>{item.fechainicio}</p>
                                    </td>
                                    <td className='w-1/12 border border-black'>
                                        <p className='text-center'>{item.fechafin}</p>
                                    </td>
                                    <td className='w-1/6 border border-black'>
                                        <input type='text' className='w-full px-2' value={item.coeficiente} onChange={(e) => handleSetCoeficiente(e.target.value, item.idcoef)} />
                                    </td>

                                    <td className='w-1/6 border border-black text-center'>
                                        <button
                                            className='bg-red-400 p-2 rounded-md text-white ml-2'
                                            onClick={() => handleDelete(item.idcoef)}
                                        >
                                            Eliminar
                                        </button>
                                    </td>
                                </tr>
                            )
                            )
                        }
                    </tbody>
                </table>
            </div>
            <div>
                <form
                    className="w-full mx-auto rounded-2xl"
                    onSubmit={formik.handleSubmit}
                >
                    <div className='flex'>
                        <div className="sm:items-start mt-5 w-1/3 pr-2">
                            <label htmlFor="fechaInicio" className="block text-gray-600 uppercase font-bold">Fecha de Inicio</label>
                            <input
                                className={`py-2 px-3 w-full rounded ${errorFecha ? 'bg-red-200' : 'bg-slate-200'} `}
                                id="fechaInicio"
                                placeholder="Descripción"
                                type="date"
                                value={formik.values.fechaInicio}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.fechaInicio && formik.errors.fechaInicio ? (
                                <div className='w-full block mb-2 text-red-500'>
                                    <p>{formik.errors.fechaInicio}</p>
                                </div>
                            ) : null}
                        </div>
                        <div className="sm:items-start mt-5 w-1/3 pr-2">
                            <label htmlFor="fechaFin" className="block text-gray-600 uppercase font-bold">Fecha de Fin</label>
                            <input
                                className="py-2 px-3 w-full rounded bg-slate-200"
                                id="fechaFin"
                                placeholder="Descripción"
                                type="date"
                                value={formik.values.fechaFin}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.fechaFin && formik.errors.fechaFin ? (
                                <div className='w-full block mb-2 text-red-500'>
                                    <p>{formik.errors.fechaFin}</p>
                                </div>
                            ) : null}
                        </div>
                        <div className="sm:items-start mt-5 w-1/6 pr-2">
                            <label htmlFor="coeficiente" className="block text-gray-600 uppercase font-bold">Coeficiente</label>
                            <input
                                className="py-2 px-3 w-full rounded bg-slate-200"
                                id="coeficiente"
                                placeholder="Valor de coeficiente"
                                type="text"
                                value={formik.values.coeficiente}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.coeficiente && formik.errors.coeficiente ? (
                                <div className='w-full block mb-2 text-red-500'>
                                    <p>{formik.errors.coeficiente}</p>
                                </div>
                            ) : null}
                        </div>
                        <div className="w-1/6 rounded-lg px-2 pt-4 my-5">
                            <input
                                type="submit"
                                className={`w-full shadow-md p-3 text-gray-100 hover:bg-indigo-400 transition-all uppercase font-bold rounded-lg  ${agregar ? 'bg-blue-500' : 'bg-blue-200'}`}
                                value="Agregar coeficiente"
                            />
                        </div>
                    </div>

                </form>

            </div>
            <div className='w-full flex'>
                {estrSelected.archivos && estrSelected.archivos.map((item, index) => (
                    <div key={index} className='mx-2'>
                        {item.tipo === 1 ? (
                            <button
                                className='bg-gray-200 rounded-t-lg mt-3 w-full mx-2 justify-items-center'
                                onClick={() => handleImageSelected(item.archivo)}
                            >
                                <img src={Url + item.archivo} className='object-cover justify-center rounded-xl mt-1 mx-auto h-32' />
                            </button>
                        ) : (
                            item.tipo === 2 ? (
                                <a href={Url + item.archivo} target="_blank" rel="noreferrer" id={12}>
                                    <button className='bg-blue-100 rounded-xl mt-3 mx-2 text-blue-600 flex text-center text-5xl p-5' type='button'><FaFilePdf className='mt-1 ml-4 mr-2' /></button>
                                </a>
                            ) :
                                <button
                                    className='bg-blue-100 w-full rounded-xl mt-3 mx-2 text-blue-600 flex text-center text-5xl p-5'
                                    type='button'
                                    onClick={() => handleDownload(Url + item.archivo, item.archivo)}
                                >
                                    {
                                        item.tipo === 3 ? (
                                            <SiMicrosoftexcel className='mt-1 ml-4 mr-2' />)
                                            : item.tipo === 4 ? (
                                                <SiMicrosoftword className='mt-1 ml-4 mr-2' />)
                                                : (<FaFileAlt className='mt-1 ml-4 mr-2' />)
                                    }
                                </button>
                        )}
                        <button
                            className='bg-red-500 w-full mx-2 rounded-b text-white p-1'
                            onClick={() => deleteFile(item)}
                        >
                            Eliminar
                        </button>
                    </div>

                ))}
                <SubirImagenes
                    id={idEstructura}
                    tipo={tipoImagen}
                    ref={childRef}
                    idHtml={idHtml}
                />
            </div>
            <div className="w-full rounded-lg mt-10 px-3 flex justify-center">
                <button
                    type="button"
                    className={`w-1/3 sm:w-1/4 shadow-md p-3 ml-3 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg ${guardar ? 'bg-blue-500' : 'bg-blue-200'} `}
                    onClick={() => handleGuardar()}
                >
                    Guardar Cambios
                </button>

                <button
                    type="button"
                    className="w-1/4 sm:w-1/6 shadow-md p-3 ml-3 bg-gray-500 text-white hover:bg-gray-400 transition-all uppercase font-bold rounded-lg"
                    onClick={() => setOpenVista(false)}
                >
                    Salir
                </button>
            </div>
        </div>
    )
}

export default FEditEstructura