import { useState, useEffect, useContext } from 'react'
import ContextStates from '../context/contextStates'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { Url } from './Url'

const ValoresInicio = () => {
    const [valores, setValores] = useState([])
    const { tipoUsrLogin, idUsuario } = useContext(ContextStates)

    useEffect(() => {
        const getValores = async () => {
            if (tipoUsrLogin!==0)
            {
                const formData = new FormData()
                formData.append('tipoUsr', tipoUsrLogin)
                formData.append('idusuario', idUsuario)
                try {
                    const response = await axios({
                        url: Url + 'api/valoresInicio.php',
                        method: 'POST',
                        data: formData,
                    })
                    setValores(response.data.results)
                } catch (e) {
                    console.log(e)
                }
    
            }
        }
        getValores()

    }, [tipoUsrLogin])



    return (
        <div>
            <div className='flex p-5'>
                <div className='w-full md:w-1/3 p-3'>
                    <Link to={"/contratos"}>
                        <div className='bg-slate-50 hover:bg-white rounded-lg p-5 h-52 border border-slate-50 shadow-lg'>
                            <h3 className='text-2xl text-center font-semibold text-gray-600 mb-5'>Contratos</h3>
                        </div>
                    </Link>
                </div>
                <div className='w-full md:w-1/3 p-3'>
                    <Link to={"/certificados"}>
                    <div className='bg-slate-50 hover:bg-white rounded-lg p-5 h-52 border border-slate-50 shadow-lg'>
                        <h3 className='text-2xl text-center font-semibold text-gray-600 mb-3'>Certificados</h3>
                        { valores && valores.map((item, index) => 
                        item.estado==='1' ?
                        <p key={index} className='text-left ml-10 text-gray-600 font-semibold'>En borrador: {item.cantidad}</p>
                        : item.estado==='2' ?
                        <p key={index} className='text-left ml-10 text-orange-600 font-semibold'>{tipoUsrLogin==='1' ? 'Enviados:' : tipoUsrLogin==='2' ? 'Enviados:' : tipoUsrLogin==='3' && 'Para revisión:'} {item.cantidad}</p>
                        : item.estado==='3' ?
                        <p key={index} className='text-left ml-10 text-blue-600 font-semibold'>{tipoUsrLogin==='1' || tipoUsrLogin==='2' ? 'Revisados por Supervisor:' : tipoUsrLogin==='4' && 'Para aprobación:'} {item.cantidad}</p>
                        : item.estado==='4' ?
                        <p key={index} className='text-left ml-10 text-green-600 font-semibold'>Aprobados: {item.cantidad}</p>
                        :
                        <p key={index} className='text-left ml-10 text-red-600 font-semibold'>Rechazados: {item.cantidad}</p>
                        )}
                    </div>
                    </Link>
                </div>
                { tipoUsrLogin==='1' && (
                <div className='w-full md:w-1/3 p-3'>
                    <Link to={"/estructuras"}>
                    <div className='bg-slate-50 hover:bg-white rounded-lg p-5 h-52 border border-slate-50 shadow-lg'>
                        <h3 className='text-2xl text-center font-semibold text-gray-600 mb-5'>Estructuras de Costos</h3>
                    </div>
                    </Link>
                </div>                
                )}
            </div>
        </div>
    )
}

export default ValoresInicio