import { useState, useEffect, useContext } from 'react'
import ContextStates from '../context/contextStates'
import axios from 'axios'
import { FaEye, FaTrash } from 'react-icons/fa'
import { AiOutlineSearch } from 'react-icons/ai'
import { GrDocumentConfig } from "react-icons/gr";
import { Url } from './Url'

const ListEstructuras = () => {
    const [coeficientes, setCoeficientes] = useState([])
    const [fechaInicio, setFechaInicio] = useState('')
    const [fechaFin, setFechaFin] = useState('')
    const [bEstructura, setBEstructura] = useState('')

    const { setTipoModal, setOpenVista, setEstrSelected, setCoefSelected, modify, tipoUsrLogin, idUsuario } = useContext(ContextStates)

    useEffect(() => {
        const getCoeficientes = async () => {
            const formData = new FormData()
            formData.append('list', 1);
            formData.append('bEstructura', bEstructura)
            try {
                const response = await axios({
                    url: Url + 'api/estructurasList.php',
                    method: 'POST',
                    data: formData,
                })
                setCoeficientes(response.data.results)
            } catch (e) {
                console.log(e)
            }
        }
        getCoeficientes()

    }, [modify, idUsuario, bEstructura])

    const asignEstructura = (element) => {
        //console.log(element.coeficientes)
        setEstrSelected({
            id: element.id,
            nombre: element.nombre,
            coeficientes: element.coeficientes,
            archivos: element.archivos
        })
    }

    const viewEstructura = async (item) => {
        asignEstructura(item);
        setTipoModal(9)
        setOpenVista(true);
    }
    const editEstructura = async (item) => {
        asignEstructura(item);
        setTipoModal(10)
        setOpenVista(true);
    }
    const delEstructura = async (item) => {
        asignEstructura(item);
        setTipoModal(12)
        setOpenVista(true);
    }


    return (
        <div className='w-full'>
            <div className='bg-white p-3 rounded-xl mb-5 shadow-lg'>
                <div className='flex'>
                    <div className='w-1/3 p-5'>
                        <label htmlFor="bEstructura" className="block text-gray-600 uppercase font-bold">Estructura</label>
                        <div className='py-3 rounded-3xl bg-slate-50 w-full mx-auto px-3 flex'>
								<input
									type="text"
                                    id="bEstructura"
									className='w-11/12 outline-none bg-slate-50'
									value={bEstructura}
									placeholder="Búsqueda por nombre de estructura"
									onChange={(e) => setBEstructura(e.target.value)}
								/>
								<AiOutlineSearch className='ml-auto text-2xl' />
							</div>
                    </div>
                </div>
            </div>
            <table className='table-auto w-full border-collapse border-spacing-5 border-slate-400 text-gray-700 rounded-xl mx-auto' >
                <thead>
                    <tr className='uppercase bg-blue-400 text-white'>
                        <th className='border-gray-400 p-1'>Nombre</th>
                        <th className='border-gray-400 p-1'>Acciones</th>
                    </tr>
                </thead>
                <tbody >
                    {
                        typeof coeficientes !== 'undefined' ? (
                            coeficientes.map((item, index) => (
                                <tr
                                    key={item.id}
                                    className={`hover:cursor-pointer border-b border-gray-200  ${index % 2 === 0 ? 'bg-white hover:bg-slate-200' : 'bg-slate-100 hover:bg-slate-200'}`}

                                >
                                    <td className='border-gray-400 p-1 text-center'>{item.nombre}</td>
                                    <td className='border-gray-400 p-1 text-center'>
                                        <button
                                            className='bg-orange-500 p-2 rounded-md text-white'
                                            onClick={() => editEstructura(item)}
                                        >
                                            <GrDocumentConfig />
                                        </button>
                                        <button
                                            className='bg-indigo-500 p-2 rounded-md text-white ml-2'
                                            onClick={() => viewEstructura(item)}
                                        >
                                            <FaEye />
                                        </button>
                                        <button
                                            className='bg-red-400 p-2 rounded-md text-white ml-2'
                                            onClick={() => delEstructura(item)}
                                        >
                                            <FaTrash />
                                        </button>

                                    </td>
                                </tr>

                            ))
                        ) : (null)
                    }
                </tbody>
            </table>
        </div>
    )
}

export default ListEstructuras