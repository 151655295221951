import React, { useState, useContext, useEffect, Fragment, useRef } from 'react'

import { Dialog, Transition } from '@headlessui/react'
import { AiFillHome, AiOutlineBarChart } from "react-icons/ai"
import { FaTruck, FaToolbox, FaUserAlt, FaListUl, FaCalendarCheck, FaHandPaper, FaUserCog } from "react-icons/fa"
import { LiaFileContractSolid } from "react-icons/lia";
import { MdOutlinePhoneAndroid } from "react-icons/md"
import { TbDecimal } from "react-icons/tb";
import { FiMenu } from "react-icons/fi"
import { GrClose } from "react-icons/gr"
import { FaListCheck } from "react-icons/fa6";
import { HiLocationMarker } from "react-icons/hi"
import { IoIosPeople } from "react-icons/io"
import { VscChecklist } from "react-icons/vsc"
import authContext from '../context/auth/authContext'
import ContextStates from '../context/contextStates'

import SideLink from './SideLink'

const SideBar = () => {
	const [show, setShow] = useState(false)
	const AuthContext = useContext(authContext);
	const { cerrarSesion } = AuthContext;
	const { roles, setTipoModal, setOpenVista, tipoUsrLogin } = useContext(ContextStates)
	const mail = localStorage.getItem('sscrt_m');

	const cancelButtonRef = useRef(null)

	useEffect(() => {
		const checkRoles = () => {
		}
		checkRoles()
	}, [roles])

	return (
		<div className='fixed z-40'>
			<button type='button'
				className={`${show ? "hidden" : "block"} z-50 relative top-2 left-2 text-white bg-slate-600 hover:bg-slate-500 flex items-center justify-center w-10 h-10 rounded-full`}
				onClick={() => setShow(true)}
			>
				<FiMenu />
			</button>
			<Transition.Root show={show} as={Fragment}>
				<Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setShow}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<div className="fixed z-10 inset-0 overflow-y-auto">
						<div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 w-0 translate-x-0"
								enterTo="opacity-100 w-full scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-x-0 scale-100"
								leaveTo="opacity-0 translate-x-4 translate-x-0"
							>
								<Dialog.Panel className="bg-black/90 fixed top-0 z-10 inset-0 text-left overflow-hidden overflow-y-auto shadow-xl transform transition-all 2xl:w-1/6 lg:w-1/4 md:w-1/2 w-11/12">
									<button
										className={`${!show ? "hidden" : "block"} relative top-2 left-2 text-white bg-gray-700 hover:bg-gray-600 flex items-center justify-center w-10 h-10 rounded-full`}
										type='button'
										value='Close Sidebar'
										onClick={() => setShow(false)}
									>
										<GrClose />
									</button>
									<div className="px-4 pt-2 sm:p-6">
										<div className="sm:flex sm:items-start">
											<div
												className={`${!show ? "hidden" : "block"}`}
											>
												<div className='overflow-y-auto flex-1'>
													<ul className="relative mt-5 mb-5 pb-5 border-b border-gray-500">
														<SideLink link="/" id={0} label="Inicio"><AiFillHome /></SideLink>
													</ul>
													<ul className="relative mb-5 pb-5">
														<SideLink link="/contratos" id={1} label="Contratos"><LiaFileContractSolid /></SideLink>
													</ul>
													<ul className="relative mb-5 pb-5">
														<SideLink link="/certificados" id={2} label="Certificados"><FaListCheck /></SideLink>
													</ul>
													{roles.includes("1") && (
														<>
															<ul className="relative mb-5 pb-5 border-b border-gray-500">
																<SideLink link="/estructuras" id={3} label="Estructuras de Costos"><TbDecimal /></SideLink>
															</ul>
															<ul className="relative mb-5 pb-5 border-b border-gray-500">
																<SideLink link="/adminusuarios" id={4} label="Administrar Usuarios"><FaUserCog /></SideLink>
															</ul>
															<ul className="relative mb-5 pb-5 border-b border-gray-500">
																<SideLink link="/reporting" id={5} label="Reportes"><AiOutlineBarChart /></SideLink>
															</ul>
														</>
													)}
													<ul className="relative mb-10 pb-10">
														<SideLink link="/perfil" id={11} label={mail}><FaUserAlt /></SideLink>
														<button className='bg-orange-500 hover:bg-orange-400 text-white text-center font-bold uppercase p-1 mb-2 rounded-xl w-full' onClick={() => cerrarSesion()}>Cerrar Sesión</button>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
		</div>
	)
}

export default SideBar