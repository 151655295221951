import React, { useState, useEffect, useContext } from 'react'
import ContextStates from '../context/contextStates';
import Layout from '../components/Layout';
import FViewReporting from '../components/FViewReporting';


const Reporting = () => {
	const { setOpenAlta, setFormAlta, tipoUsrLogin } = useContext(ContextStates);
	return (
		<Layout
			pagina="Reportes"
		>
			{tipoUsrLogin === '1' && (
				<FViewReporting />
			)}
		</Layout>
	)
}

export default Reporting